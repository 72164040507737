import { useEffect, useState } from 'react';

import { useGetNotificationsMutation } from '@apis/notifications/Notifications.api';
import { NotificationRequest } from '@customTypes/notifications/notifications.type';
import {
    // TODO making notifications as read is out of the scope and will be done in a separate ticket
    // markAllAsRead,
    // markAsRead,
    // markAsRead,
    resetNotificationsState
} from '@store/slices/notifications/notifications';
import { useAppDispatch, useAppSelector } from 'store';

const DEFAULT_REQUEST_PAYLOAD = {
    sorts: '-CreatedDate',
    page: 1,
    pageSize: 5
};

interface Props {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

export const useNotificationPanel = ({ isOpen, setIsOpen }: Props) => {
    const dispatch = useAppDispatch();
    const [getNotifications] = useGetNotificationsMutation();
    const [notificationRequest, setNotificationRequest] =
        useState<NotificationRequest | null>(null);

    const {
        data: notifications,
        loading,
        totalCount
    } = useAppSelector(({ notifications: { data, totalCount, loading } }) => ({
        data,
        totalCount,
        loading
    }));

    const getNotificationsData = () => {
        const request = getNotifications(DEFAULT_REQUEST_PAYLOAD);
        setNotificationRequest(request);
    };

    useEffect(() => {
        if (isOpen) {
            getNotificationsData();
        }
    }, [isOpen]);

    const handleOpenChange = (newOpen: boolean) => {
        setIsOpen(newOpen);
        if (!newOpen) {
            notificationRequest?.abort();
            dispatch(resetNotificationsState());
        }
    };

    const handleLoadMore = () => {
        if (!loading) {
            const nextPage =
                Math.ceil(
                    notifications.length / DEFAULT_REQUEST_PAYLOAD.pageSize
                ) + 1;

            getNotifications({
                ...DEFAULT_REQUEST_PAYLOAD,
                page: nextPage
            });
        }
    };

    return {
        totalCount,
        loading,
        notifications,
        handleLoadMore,
        // handleMarkAsRead,
        // onMarkAllAsReadBtnClick,
        handleOpenChange
    };
};
