import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.d';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { bulkResponseHandler } from '@apis/bulkResponseHandler';
import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import {
    REQUESTS_PATH,
    REQUESTS_PATH_DEFECTIVE
} from '@apis/inboundByBulk/InboundByBulk.type';
import { IQueryWithIntercept } from '@apis/queryWithIntercept';
import { RequestStatusWithEmptyData } from '@customTypes/api/api.types';
import {
    BULK_TEMPLATES_NAMES,
    ColumnMappingDTO,
    ColumnMappingResponse,
    DownloadTemplateDTO,
    GroupingOrdersDTO,
    GroupingOrdersResponse,
    SubmitOrdersDTO,
    ValidateFileDTO,
    ValidateFileResponse
} from '@customTypes/bulkOrder/bulk.types';

const getCommonEndpoints = (
    builder: EndpointBuilder<IQueryWithIntercept, never, string>,
    requestsPath: typeof REQUESTS_PATH | typeof REQUESTS_PATH_DEFECTIVE,
    isDefective = false
) => ({
    getDownloadInboundTemplate: builder.mutation<File, DownloadTemplateDTO>({
        query: (dto) => ({
            url: requestsPath.DOWNLOAD_TEMPLATE,
            headers: {
                responseType: 'blob',
                'content-type': 'application/octet-stream'
            },
            responseHandler: async (response) =>
                bulkResponseHandler(
                    response,
                    dto,
                    isDefective
                        ? BULK_TEMPLATES_NAMES.INBOUND_DEFECTIVE
                        : BULK_TEMPLATES_NAMES.INBOUND
                ),
            cache: 'no-cache',
            method: 'POST',
            body: dto
        })
    }),
    resetInboundFile: builder.mutation<RequestStatusWithEmptyData, string>({
        query: (id) => ({
            url: requestsPath.RESET_FILE.replace(':id', id),
            method: 'DELETE'
        })
    }),
    getInboundColumnsMapping: builder.mutation<
        ColumnMappingResponse,
        ColumnMappingDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_COLUMNS_MAPPING,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    validateInboundFile: builder.mutation<
        ValidateFileResponse,
        ValidateFileDTO
    >({
        query: (dto) => ({
            url: requestsPath.VALIDATE_FILE,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    getInboundGroupingOrders: builder.mutation<
        GroupingOrdersResponse,
        GroupingOrdersDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_GROUPING,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    submitInboundOrders: builder.mutation<
        RequestStatusWithEmptyData,
        SubmitOrdersDTO
    >({
        query: (dto) => ({
            url: requestsPath.SUBMIT_ORDERS,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    })
});

export const inboundByBulkApi = createApi({
    reducerPath: 'inboundByBulkApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, REQUESTS_PATH)
});

export const inboundByBulkDefectiveApi = createApi({
    reducerPath: 'inboundByBulkDefectiveApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) =>
        getCommonEndpoints(builder, REQUESTS_PATH_DEFECTIVE, true)
});

export const {
    useGetDownloadInboundTemplateMutation,
    useResetInboundFileMutation,
    useGetInboundColumnsMappingMutation,
    useValidateInboundFileMutation,
    useGetInboundGroupingOrdersMutation,
    useSubmitInboundOrdersMutation
} = inboundByBulkApi;

export const {
    useGetDownloadInboundTemplateMutation:
        useDefectiveGetDownloadInboundTemplateMutation,
    useResetInboundFileMutation: useDefectiveResetInboundFileMutation,
    useGetInboundColumnsMappingMutation:
        useDefectiveGetInboundColumnsMappingMutation,
    useValidateInboundFileMutation: useDefectiveValidateInboundFileMutation,
    useGetInboundGroupingOrdersMutation:
        useDefectiveGetInboundGroupingOrdersMutation,
    useSubmitInboundOrdersMutation: useDefectiveSubmitInboundOrdersMutation
} = inboundByBulkDefectiveApi;
