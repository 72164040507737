import React from 'react';

import { Col, Row } from 'antd';

import { ListItem } from '@customTypes/general/general.types';
import { BlockTitle } from '@organisms/userSettings/blockTitle/BlockTitle';
import { CustomRadioGroup } from '@organisms/userSettings/customRadioGroup/CustomRadioGroup';

export interface IProps {
    title: string;
    data: ListItem[];
    groupName: string;
}

export const RowWithRadioGroup = ({ title, data, groupName }: IProps) => (
    <Row data-testid="row-with-radio-group" align="middle">
        <Col xs={24} lg={4}>
            <BlockTitle title={title} />
        </Col>
        <Col xs={24} lg={20}>
            <CustomRadioGroup name={groupName} data={data} />
        </Col>
    </Row>
);
