import { NavigationKeys, RoutesList } from '@constants/routesList';
import { IRedirectList } from '@customTypes/navigation/navigation.types';
import { RootState } from '@store/index';
import { resetOrderState } from '@store/slices/activeOrder/activeOrder';
import { resetBulkState } from '@store/slices/bulk/bulk';

const {
    home,
    trackOrders,
    customerDelivery,
    replenishments,
    createTransfer,
    returnParts,
    manageInventory,
    billing,
    analytics,
    adminPanel: { admins, users, profiles, choiceAdmins }
} = NavigationKeys;

export const RedirectList: IRedirectList[] = [
    // HOME
    {
        key: home.key,
        redirectTo: RoutesList.HOME.ROOT,
        permission: home.permissions
    },
    // TRACK ORDERS
    {
        key: trackOrders.key,
        redirectTo: RoutesList.TRACK_ORDERS.ROOT,
        nestedItems: [RoutesList.TRACK_ORDERS.ORDER_DETAILS.VIEW_FULL],
        permission: trackOrders.permissions
    },
    // ORDERS
    {
        key: customerDelivery.goodByPart.key,
        redirectTo: RoutesList.ORDERS.BY_PART.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetOrderState,
        permission: customerDelivery.goodByPart.permission
    },
    {
        key: customerDelivery.goodByUpload.key,
        redirectTo: RoutesList.ORDERS.BULK.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: customerDelivery.goodByUpload.permission
    },
    // Replenishments
    {
        key: replenishments.inboundReplenishment.key,
        redirectTo: RoutesList.REPLENISHMENTS.GOOD.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetOrderState,
        permission: replenishments.inboundReplenishment.permission
    },
    {
        key: replenishments.inboundGoodByUpload.key,
        redirectTo: RoutesList.REPLENISHMENTS.BULK.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: replenishments.inboundGoodByUpload.permission
    },
    {
        key: createTransfer.transferGoodByWarehouse.key,
        redirectTo: RoutesList.TRANSFERS.GOOD.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetOrderState,
        permission: createTransfer.transferGoodByWarehouse.permission
    },
    {
        key: createTransfer.transferDefectiveByWarehouse.key,
        redirectTo: RoutesList.TRANSFERS.DEFECTIVE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetOrderState,
        permission: createTransfer.transferDefectiveByWarehouse.permission
    },
    {
        key: createTransfer.transferGoodByUpload.key,
        redirectTo: RoutesList.TRANSFERS.UPLOAD.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: createTransfer.transferGoodByUpload.permission
    },
    {
        key: createTransfer.transferDefectiveByUpload.key,
        redirectTo: RoutesList.TRANSFERS.UPLOAD_DEFECTIVE.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: createTransfer.transferDefectiveByUpload.permission
    },
    // RETURNS
    {
        key: returnParts.defectiveToChoice.key,
        redirectTo: RoutesList.RETURNS.DEFECTIVE_TO_CHOICE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: returnParts.defectiveToChoice.permission
    },
    {
        key: returnParts.defectiveToChoiceUpload.key,
        redirectTo: RoutesList.RETURNS.DEFECTIVE_TO_CHOICE_UPLOAD.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: returnParts.defectiveToChoiceUpload.permission
    },
    {
        key: returnParts.goodToChoice.key,
        redirectTo: RoutesList.RETURNS.GOOD_TO_CHOICE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: returnParts.goodToChoice.permission
    },
    {
        key: returnParts.defectiveFromChoice.key,
        redirectTo: RoutesList.RETURNS.DEFECTIVE_FROM_CHOICE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: returnParts.defectiveFromChoice.permission
    },
    {
        key: returnParts.defectiveFromChoiceUpload.key,
        redirectTo: RoutesList.RETURNS.DEFECTIVE_FROM_CHOICE_UPLOAD.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: returnParts.defectiveFromChoiceUpload.permission
    },
    {
        key: returnParts.goodFromChoice.key,
        redirectTo: RoutesList.RETURNS.GOOD_FROM_CHOICE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: returnParts.goodFromChoice.permission
    },

    // MANAGE INVENTORY
    {
        key: manageInventory.partMaster.key,
        redirectTo: RoutesList.MANAGE_INVENTORY.PART_MASTER.READ_FULL,
        permission: manageInventory.partMaster.permission
    },
    {
        key: manageInventory.substituteParts.key,
        redirectTo: RoutesList.MANAGE_INVENTORY.SUBSTITUTE_PARTS.READ_FULL,
        permission: manageInventory.substituteParts.permission
    },
    {
        key: manageInventory.locateClosestWarehouse.key,
        redirectTo: RoutesList.MANAGE_INVENTORY.LOCATE_CLOSEST_WAREHOUSE.FULL,
        getRedirectBlocker: () => false,
        resetStateAction: resetOrderState,
        permission: manageInventory.locateClosestWarehouse.permission
    },
    {
        key: manageInventory.materialInReview.key,
        redirectTo: RoutesList.MANAGE_INVENTORY.MATERIALS_REVIEW.FULL,
        getRedirectBlocker: () => false,
        nestedItems: [
            RoutesList.MANAGE_INVENTORY.MATERIALS_REVIEW.MIR_DETAILS.VIEW_FULL
        ],
        permission: manageInventory.materialInReview.permission
    },
    {
        key: manageInventory.materialInReviewAnalysis.key,
        redirectTo:
            RoutesList.MANAGE_INVENTORY.MATERIALS_IN_REVIEW_ANALYSIS.FULL,
        permission: manageInventory.materialInReviewAnalysis.permission
    },
    {
        key: manageInventory.missingParts.key,
        redirectTo: RoutesList.MANAGE_INVENTORY.MISSING_PARTS.FULL,
        permission: manageInventory.missingParts.permission
    },
    {
        key: manageInventory.inventoryTransactionHistory.key,
        redirectTo:
            RoutesList.MANAGE_INVENTORY.INVENTORY_TRANSACTION_HISTORY.FULL,
        permission: manageInventory.inventoryTransactionHistory.permission
    },
    {
        key: manageInventory.inventoryOnHand.key,
        redirectTo: RoutesList.MANAGE_INVENTORY.INVENTORY_ON_HAND.FULL,
        permission: manageInventory.inventoryOnHand.permission
    },
    // BILLING
    {
        key: billing.invoices.key,
        redirectTo: RoutesList.BILLING.INVOICES.VIEW_FULL,
        permission: billing.invoices.permission
    },
    {
        key: billing.storageBilling.key,
        redirectTo: RoutesList.BILLING.STORAGE_BILLING.VIEW_FULL,
        permission: billing.storageBilling.permission
    },
    {
        key: billing.invoiceBilling.key,
        redirectTo: RoutesList.BILLING.INVOICE_BILLING.VIEW_FULL,
        permission: billing.invoiceBilling.permission
    },
    // ANALYTICS
    {
        key: analytics.dutiesTaxesAnalysis.key,
        redirectTo: RoutesList.ANALYTICS.DUTIES_TAXES_ANALYSIS.VIEW_FULL,
        permission: analytics.dutiesTaxesAnalysis.permission
    },
    {
        key: analytics.spendAnalysis.key,
        redirectTo: RoutesList.ANALYTICS.CLIENT_SPEND.VIEW_FULL,
        permission: analytics.spendAnalysis.permission
    },
    {
        key: analytics.orderHistory.key,
        redirectTo: RoutesList.ANALYTICS.ORDER_HISTORY.READ_FULL,
        permission: analytics.orderHistory.permission
    },
    {
        key: analytics.cycleCountPerformance.key,
        redirectTo: RoutesList.ANALYTICS.CYCLE_COUNT_PERFORMANCE.READ_FULL,
        permission: analytics.cycleCountPerformance.permission
    },
    {
        key: analytics.globalCapabilities.key,
        redirectTo: RoutesList.ANALYTICS.GLOBAL_CAPABILITIES.READ_FULL,
        permission: analytics.globalCapabilities.permission
    },
    {
        key: analytics.dockToStock.key,
        redirectTo: RoutesList.ANALYTICS.DOCK_TO_STOCK.READ_FULL,
        permission: analytics.dockToStock.permission
    },
    {
        key: analytics.outboundDriveFillRate.key,
        redirectTo: RoutesList.ANALYTICS.OUTBOUND_DRIVE_FILL_RATE.READ_FULL,
        permission: analytics.outboundDriveFillRate.permission
    },
    {
        key: analytics.outboundDrivePerformance.key,
        redirectTo: RoutesList.ANALYTICS.OUTBOUND_DRIVE_PERFORMANCE.READ_FULL,
        permission: analytics.outboundDrivePerformance.permission
    },
    {
        key: analytics.tenderToCarrierPerformance.key,
        redirectTo:
            RoutesList.ANALYTICS.TENDER_TO_CARRIER_PERFORMANCE.READ_FULL,
        permission: analytics.tenderToCarrierPerformance.permission
    },
    // ADMIN PANEL
    {
        key: choiceAdmins.key,
        redirectTo: RoutesList.ADMIN_PANEL.CHOICE_ADMIN.READ.FULL,
        nestedItems: [
            RoutesList.ADMIN_PANEL.CHOICE_ADMIN.CREATE.FULL,
            RoutesList.ADMIN_PANEL.CHOICE_ADMIN.EDIT.FULL
        ],
        getRedirectBlocker: ({ adminPanel }: RootState) =>
            adminPanel.redirectBlocker,
        permission: choiceAdmins.key
    },
    {
        key: profiles.key,
        redirectTo: RoutesList.ADMIN_PANEL.PROFILES.READ.FULL,
        nestedItems: [
            RoutesList.ADMIN_PANEL.PROFILES.CREATE.FULL,
            RoutesList.ADMIN_PANEL.PROFILES.VIEW.FULL,
            RoutesList.ADMIN_PANEL.PROFILES.EDIT.FULL
        ],
        getRedirectBlocker: ({ adminPanel }: RootState) =>
            adminPanel.redirectBlocker,
        permission: profiles.permission
    },
    {
        key: admins.key,
        redirectTo: RoutesList.ADMIN_PANEL.ADMINS.READ.FULL,
        nestedItems: [
            RoutesList.ADMIN_PANEL.ADMINS.CREATE.FULL,
            RoutesList.ADMIN_PANEL.ADMINS.EDIT.FULL
        ],
        getRedirectBlocker: ({ adminPanel }: RootState) =>
            adminPanel.redirectBlocker,
        permission: admins.permission
    },
    {
        key: users.key,
        redirectTo: RoutesList.ADMIN_PANEL.USERS.READ.FULL,
        nestedItems: [
            RoutesList.ADMIN_PANEL.USERS.CREATE.FULL,
            RoutesList.ADMIN_PANEL.USERS.EDIT.FULL
        ],
        getRedirectBlocker: ({ adminPanel }: RootState) =>
            adminPanel.redirectBlocker,
        permission: users.permission
    }
];
