import React, {
    useCallback,
    useState,
    forwardRef,
    ForwardedRef,
    useRef,
    useEffect
} from 'react';

import { Select as AntSelect, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import cn from 'classnames';
import type { BaseSelectRef } from 'rc-select';
import { DisplayValueType } from 'rc-select/lib/BaseSelect';

import { SearchBadge } from '@atoms/searchBadge/SearchBadge';
import { SelectPrefix } from '@atoms/selectPrefix/SelectPrefix';
import { capitalize } from '@helpers/stringHelper';
import { SvgCaretDown, SvgCross, SvgArrow } from 'components/icons';
import { VoidFunction } from 'utils/types/general/general.types';

import styles from './Select.module.less';

export interface ISelectProps extends SelectProps {
    data?: React.ReactNode[] | React.ReactNode;
    onSelect?: (title: string, data: DefaultOptionType) => void;
    hasError?: boolean;
    prefixIcon?: React.ReactNode;
    prefixClassName?: string;
    onFilterClean?: VoidFunction;
    required?: boolean;
    containerClassName?: string;
}

const defaultListItemHeight = 33;
const RESET_SCROLL_DELAY = 200;

export const Select = forwardRef(function Select(
    {
        data,
        options,
        hasError = false,
        placeholder,
        showSearch,
        dropdownMatchSelectWidth = true,
        disabled = false,
        bordered = true,
        notFoundContent = 'No results found',
        id,
        value,
        prefixIcon,
        prefixClassName,
        onFilterClean,
        listItemHeight = defaultListItemHeight,
        className,
        onDropdownVisibleChange,
        containerClassName,
        ...props
    }: ISelectProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const showPrefix = Boolean(prefixIcon) || showSearch;
    const dropdownRef = useRef<BaseSelectRef>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const resetScrollPosition = () => {
        if (dropdownRef.current) {
            dropdownRef.current.scrollTo({ index: 0 });
        }
    };

    const handleDropdownVisibleChange = (open: boolean) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (!open) {
            timeoutRef.current = setTimeout(
                resetScrollPosition,
                RESET_SCROLL_DELAY
            );
        }
        onDropdownVisibleChange?.(open);
        setIsDropdownOpen(open);
    };

    const CustomSuffixIcon = () => {
        return (
            <span
                className={`${isDropdownOpen ? styles.CustomSuffixIcon : ''}`}
            >
                <SvgArrow color="#8E94A9" />
            </span>
        );
    };

    const getMaxTagPlaceholder = useCallback(
        (data: DisplayValueType[]) => {
            if (onFilterClean) {
                const tag = data[0]?.value as string;
                return (
                    <SearchBadge
                        removeBadges={onFilterClean}
                        text={capitalize(`${tag?.slice(0, 3)}...` || '')}
                        id={tag}
                        crossSize="small"
                    />
                );
            }
        },
        [onFilterClean]
    );

    return (
        <div
            className={cn(
                styles.Container,
                {
                    [styles.Container__WithPrefix]: showPrefix
                },
                containerClassName
            )}
            id={id}
            data-testid="container_select_prefix"
            ref={ref}
        >
            {showPrefix && (
                <SelectPrefix disabled={disabled} className={prefixClassName} />
            )}
            <AntSelect
                open={isDropdownOpen}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                options={options}
                className={className}
                ref={dropdownRef}
                {...props}
                clearIcon={
                    <div className={styles.Icon__Wrapper}>
                        <SvgCross className={styles.Icon__Cross} />
                    </div>
                }
                notFoundContent={
                    <div className={styles.Select__Empty}>
                        {notFoundContent}
                    </div>
                }
                maxTagPlaceholder={
                    props.maxTagPlaceholder || getMaxTagPlaceholder
                }
                value={value}
                data-testid="select"
                status={hasError ? 'error' : ''}
                showSearch={showSearch}
                dropdownClassName={styles.Select__DropDown}
                placeholder={placeholder}
                suffixIcon={
                    bordered ? (
                        <CustomSuffixIcon />
                    ) : (
                        <SvgCaretDown data-testid="caret-down" />
                    )
                }
                disabled={disabled}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                bordered={bordered}
                listItemHeight={listItemHeight}
            >
                {data}
            </AntSelect>
        </div>
    );
});
