import { AppController } from '@configs/AppController';

export const addGoogleScript = () => {
    const googleConfigKey =
        AppController.getInstance().config?.googleAnalyticsKey;

    if (googleConfigKey) {
        const googleScript = document.createElement('script');
        googleScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleConfigKey}`;
        googleScript.async = true;
        googleScript.id = 'google-analytics';
        document.body.appendChild(googleScript);

        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleConfigKey}');
          `;
        document.body.appendChild(inlineScript);
    }
};
