import { createSlice } from '@reduxjs/toolkit';

import {
    IOrderTicketPayload,
    OrderTicketsById
} from '@customTypes/mirAndTracking/MirAndTracking.types';
import { IAction } from 'store/index';

export interface IInitialState {
    orderTicketsById: OrderTicketsById;
}

const initialState: IInitialState = {
    orderTicketsById: {}
};

export const trackOrdersSlice = createSlice({
    name: 'trackOrders',
    initialState,
    reducers: {
        setOrderTicketsById(
            state,
            {
                payload
            }: IAction<
                {
                    orderId: string;
                } & IOrderTicketPayload
            >
        ) {
            state.orderTicketsById[payload.orderId] = {
                data: payload.data,
                isLoading: payload.isLoading,
                expanded: payload.expanded
            };
        }
    }
});

export const { setOrderTicketsById } = trackOrdersSlice.actions;
