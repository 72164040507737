import React from 'react';

import { Select as AntSelect } from 'antd';
import classNames from 'classnames';

import { Pagination } from '@atoms/pagination/Pagination';
import { Select } from '@atoms/select/Select';
import { ITableProps, Table } from '@atoms/table/Table';
import { defaultPerPageList } from '@constants/pagination';

import styles from './PaginationTable.module.less';

const { Option } = AntSelect;

interface IPerPage {
    perPageOptions?: number[];
    perPageValue: number;
    onChangePerPage: (value: string) => void;
}

interface IProps<T> extends ITableProps<T> {
    total?: number;
    currentPage: number;
    pageCount?: number;
    customContainerClassName?: string;
    onPageChange?: (page: number) => void;
    isLoading: boolean;
    showTotal?: boolean;
    perPage?: IPerPage;
    pageSize?: number;
    footerClass?: string;
}

export const PaginationTable = <T extends Record<'id', string>>({
    total = 0,
    onPageChange,
    currentPage,
    pageCount,
    customContainerClassName = undefined,
    pageSize,
    perPage,
    showTotal,
    footerClass,
    ...props
}: IProps<T>) => {
    const perPageList = perPage?.perPageOptions || defaultPerPageList;

    return (
        <div className={classNames(styles.Container, customContainerClassName)}>
            <div className={styles.Container__Table} id="TableContainer">
                <Table {...props} />
            </div>
            {Boolean(total > perPageList[0]) && (
                <div
                    className={classNames(
                        styles.Container__Footer,
                        footerClass
                    )}
                    id="TablePaginationContainer"
                >
                    {pageCount && pageCount > 1 ? (
                        <div className={styles.Footer__Pagination}>
                            <Pagination
                                current={currentPage}
                                total={total}
                                onChange={onPageChange}
                                pageSize={perPage?.perPageValue || pageSize}
                                showTotal={
                                    showTotal
                                        ? (total, range) =>
                                              `${range[0]} - ${range[1]} of ${total} results`
                                        : undefined
                                }
                            />
                        </div>
                    ) : null}
                    {Boolean(perPage) && (
                        <div
                            className={styles.Footer__PerPage}
                            data-testid="ShowPerPageBlock"
                        >
                            <span
                                className={styles.PerPage__Title}
                                data-testid="ShowPerPageTitle"
                            >
                                Show items per page
                            </span>
                            <Select
                                dropdownMatchSelectWidth={false}
                                onSelect={perPage?.onChangePerPage}
                                value={perPage?.perPageValue}
                                data={perPageList.map((item) => (
                                    <Option
                                        key={item}
                                        value={item}
                                        data={item}
                                        id={item}
                                    >
                                        {item}
                                    </Option>
                                ))}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
