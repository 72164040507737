import { createSlice } from '@reduxjs/toolkit';

import {
    materialsTransferByWarehouseApi,
    materialsTransferByWarehouseDefectiveApi
} from '@apis/materialsTransferByWarehouse/MaterialsTransferByWarehouse.api';
import { outboundOrderByPartApi } from '@apis/outboundOrderByPart/OutboundOrderByPart.api';
import {
    outboundOrderByWarehouseApi,
    outboundOrderByWarehouseDefectiveApi
} from '@apis/outboundOrderByWarehouse/OutboundOrderByWarehouse.api';
import { initialPaginationState } from '@constants/pagination';
import { MasterInitialState } from '@customTypes/api/api.types';
import { Pagination } from '@customTypes/general/general.types';
import { GetSerialsTransformedResponse } from '@customTypes/general/part.types';
import { handleFulfilledPagination } from '@helpers/handleFulfilledPagination';
import { IAction } from 'store/index';

export interface IInitialSerialsMaster extends MasterInitialState {
    data: GetSerialsTransformedResponse['data']['items'];
    activePartNumber: string;
}

const initialState: IInitialSerialsMaster = {
    loading: false,
    data: [],
    activePartNumber: '',
    pagination: initialPaginationState,
    errors: { code: 0, message: '' }
};

export const serialsMasterSlice = createSlice({
    name: 'serialsMaster',
    initialState,
    reducers: {
        editPagination(
            state,
            action: IAction<
                Partial<Omit<Pagination, 'pagesCount' | 'totalItemsCount'>>
            >
        ) {
            state.pagination = {
                ...state.pagination,
                ...action.payload
            };
        },
        setActivePartNumber(state, { payload }: IAction<string>) {
            state.activePartNumber = payload;
        },
        resetSerialsMaster() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            outboundOrderByPartApi.endpoints.getSerialNumbers.matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            outboundOrderByWarehouseApi.endpoints.getSerialNumbers
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            outboundOrderByWarehouseDefectiveApi.endpoints.getSerialNumbers
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseApi.endpoints.getSerialNumbers
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseDefectiveApi.endpoints.getSerialNumbers
                .matchFulfilled,
            handleFulfilledPagination
        );
    }
});

export const { editPagination, setActivePartNumber, resetSerialsMaster } =
    serialsMasterSlice.actions;
