export interface B2CType {
    authority: string;
    authorityDomain: string;
    clientId: string;
    scope: string;
    authPath: string;
}

export interface FeatureFlagsType {
    version: string;
    env: string;
    flags: Record<string, boolean>;
}

export interface GoogleKeysConfig {
    googleMapsKey: string;
    googleAnalyticsKey: string;
}

export interface MainConfigType {
    b2c: B2CType;
    api_host: string;
    static_host: string;
    api_sockets: string;
    googleMapsKey: string;
    googleAnalyticsKey: string;
    dundasBIUrl: string;
    googleKeys?: GoogleKeysConfig;
}

export interface AppConfigType {
    appConfig: MainConfigType;
    featureFlags?: FeatureFlagsType;
    googleKeys?: GoogleKeysConfig;
}

export enum APP_ERRORS {
    BROKEN_FILE = 'Config file was broken',
    NO_FILE = 'No config file'
}
