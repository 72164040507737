import React from 'react';

import { ToolTip } from '@atoms/tooltip/ToolTip';
import { FeatureFlagsController } from '@configs/FeatureFlagsController';
import { DeviceType } from '@constants/breakpoints';
import { MENU_SECTIONS_PERMISSION_KEYS } from '@constants/permissionsKeys';
import { NavigationKeys } from '@constants/routesList';
import { SHOW_MODE } from '@customTypes/general/featureFlags.types';
import { ElementsType } from '@molecules/siderMenu/SiderMenuItem.types';
import { UserTokenInfo } from '@store/slices/user/user';
import {
    SvgCreateInbound,
    SvgCreateOutbound,
    SvgCreateTransfers,
    SvgFinancials,
    SvgManageInventory,
    SvgManageParts,
    SvgPerformance,
    SvgPin,
    SvgSideChoiceLogo,
    SvgSiteAdministration,
    SvgUsers
} from 'components/icons';

import styles from './SiderMenu.module.less';

interface IGetSideBarItem {
    userInfo: UserTokenInfo | null;
    withoutFlagsAndPermissions?: boolean;
    itemsWithPermissions?: ElementsType[];
}

type GetAllSideBarType = (
    userInfo: UserTokenInfo | null,
    withoutFlagsAndPermissions?: boolean
) => ElementsType[];

const {
    home,
    trackOrders,
    customerDelivery,
    replenishments,
    createTransfer,
    returnParts,
    manageInventory,
    billing,
    analytics,
    adminPanel: { admins, users, profiles, choiceAdmins }
} = NavigationKeys;

export const SideBarItemsWithPermissions: ElementsType[] = [
    {
        label: 'Home',
        key: home.key,
        icon: <SvgSideChoiceLogo />,
        mode: SHOW_MODE.ALWAYS,
        permission: home.permissions
    },
    {
        label: 'Track Orders',
        key: trackOrders.key,
        icon: <SvgPin />,
        mode: SHOW_MODE.ALWAYS,
        permission: trackOrders.permissions
    },
    {
        label: 'Customer Delivery',
        key: customerDelivery.key,
        mode: SHOW_MODE.ALWAYS,
        permission: customerDelivery.permission,
        icon: <SvgCreateOutbound />,
        children: [
            {
                label: 'Good Parts',
                key: customerDelivery.goodByPart.key,
                permission: customerDelivery.goodByPart.permission,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Good Parts (Upload)',
                permission: customerDelivery.goodByUpload.permission,
                key: customerDelivery.goodByUpload.key,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    {
        label: 'Replenishments',
        key: replenishments.key,
        mode: SHOW_MODE.ALWAYS,
        icon: <SvgManageParts />,
        permission: replenishments.permission,
        children: [
            {
                label: 'Good Parts',
                key: replenishments.inboundReplenishment.key,
                permission: replenishments.inboundReplenishment.permission,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Good Parts (Upload)',
                key: replenishments.inboundGoodByUpload.key,
                permission: replenishments.inboundGoodByUpload.permission,
                mode: SHOW_MODE.ALWAYS,
                blocked: DeviceType.Tablet
            }
        ]
    },
    {
        label: 'Warehouse Transfers',
        key: createTransfer.key,
        mode: SHOW_MODE.ALWAYS,
        icon: <SvgCreateTransfers />,
        permission: createTransfer.permission,
        children: [
            {
                label: 'Good Parts',
                key: createTransfer.transferGoodByWarehouse.key,
                permission: createTransfer.transferGoodByWarehouse.permission,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Good Parts (Upload)"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Good Parts (Upload)
                    </ToolTip>
                ),
                key: createTransfer.transferGoodByUpload.key,
                permission: createTransfer.transferGoodByUpload.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Defective Parts',
                key: createTransfer.transferDefectiveByWarehouse.key,
                permission:
                    createTransfer.transferDefectiveByWarehouse.permission,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Defective Parts (Upload)"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Defective Parts (Upload)
                    </ToolTip>
                ),
                key: createTransfer.transferDefectiveByUpload.key,
                permission: createTransfer.transferDefectiveByUpload.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    //
    {
        label: 'Returns',
        key: returnParts.key,
        mode: SHOW_MODE.ALWAYS,
        icon: <SvgCreateInbound />,
        permission: returnParts.permission,
        children: [
            {
                label: 'Defective to Choice',
                permission: returnParts.defectiveToChoice.permission,
                mode: SHOW_MODE.ALWAYS,
                key: returnParts.defectiveToChoice.key
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Defective to Choice (Upload)"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Defective to Choice (Upload)
                    </ToolTip>
                ),
                permission: returnParts.defectiveToChoiceUpload.permission,
                mode: SHOW_MODE.ALWAYS,
                blocked: DeviceType.Tablet,
                key: returnParts.defectiveToChoiceUpload.key
            },
            {
                label: 'Good to Choice',
                permission: returnParts.goodToChoice.permission,
                mode: SHOW_MODE.ALWAYS,
                key: returnParts.goodToChoice.key
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Defective from Choice"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Defective from Choice
                    </ToolTip>
                ),
                permission: returnParts.defectiveFromChoice.permission,
                mode: SHOW_MODE.ALWAYS,
                key: returnParts.defectiveFromChoice.key
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Defective from Choice (Upload)"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Defective from Choice (Upload)
                    </ToolTip>
                ),
                permission: returnParts.defectiveFromChoiceUpload.permission,
                key: returnParts.defectiveFromChoiceUpload.key,
                mode: SHOW_MODE.ALWAYS,
                blocked: DeviceType.Tablet
            },
            {
                label: 'Good from Choice',
                key: returnParts.goodFromChoice.key,
                permission: returnParts.goodFromChoice.permission,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    {
        label: 'Manage Inventory',
        key: manageInventory.key,
        mode: SHOW_MODE.ALWAYS,
        icon: <SvgManageInventory />,
        permission: manageInventory.permission,
        children: [
            {
                label: 'Inventory On Hand',
                key: manageInventory.inventoryOnHand.key,
                permission: manageInventory.inventoryOnHand.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Inventory Transaction History"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Inventory Transaction History
                    </ToolTip>
                ),
                key: manageInventory.inventoryTransactionHistory.key,
                permission:
                    manageInventory.inventoryTransactionHistory.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Material In Review',
                key: manageInventory.materialInReview.key,
                permission: manageInventory.materialInReview.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Materials In Review Analysis"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Materials In Review Analysis
                    </ToolTip>
                ),
                key: manageInventory.materialInReviewAnalysis.key,
                permission: manageInventory.materialInReviewAnalysis.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Part Master',
                key: manageInventory.partMaster.key,
                permission: manageInventory.partMaster.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Substitute Parts',
                key: manageInventory.substituteParts.key,
                permission: manageInventory.substituteParts.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Missing Parts',
                key: manageInventory.missingParts.key,
                permission: manageInventory.missingParts.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Locate Closest Warehouse',
                key: manageInventory.locateClosestWarehouse.key,
                permission: manageInventory.locateClosestWarehouse.permission,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    {
        label: 'Billing',
        key: billing.key,
        mode: SHOW_MODE.ALWAYS,
        permission: billing.permission,
        icon: <SvgFinancials />,
        children: [
            {
                label: 'Invoices',
                key: billing.invoices.key,
                permission: billing.invoices.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Storage Billing',
                key: billing.storageBilling.key,
                permission: billing.storageBilling.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Invoice Billing',
                key: billing.invoiceBilling.key,
                permission: billing.invoiceBilling.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    {
        label: 'Analytics',
        key: analytics.key,
        mode: SHOW_MODE.ALWAYS,
        permission: analytics.permission,
        icon: <SvgPerformance />,
        children: [
            {
                label: 'Spend Analysis',
                key: analytics.spendAnalysis.key,
                permission: analytics.spendAnalysis.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Duties & Taxes Analysis',
                key: analytics.dutiesTaxesAnalysis.key,
                permission: analytics.dutiesTaxesAnalysis.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Order History',
                key: analytics.orderHistory.key,
                permission: analytics.orderHistory.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Cycle Count Performance',
                key: analytics.cycleCountPerformance.key,
                permission: analytics.cycleCountPerformance.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Global Capabilities',
                key: analytics.globalCapabilities.key,
                permission: analytics.globalCapabilities.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Dock to Stock',
                key: analytics.dockToStock.key,
                permission: analytics.dockToStock.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Outbound Drive Fill Rate"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Outbound Drive Fill Rate
                    </ToolTip>
                ),
                key: analytics.outboundDriveFillRate.key,
                permission: analytics.outboundDriveFillRate.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Outbound Drive Performance',
                key: analytics.outboundDrivePerformance.key,
                permission: analytics.outboundDrivePerformance.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Tender to Carrier Performance"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Tender to Carrier Performance
                    </ToolTip>
                ),
                key: analytics.tenderToCarrierPerformance.key,
                permission: analytics.tenderToCarrierPerformance.permission,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    }
];

const getAdminPanelChildren = (
    withoutFlagsAndPermissions: boolean,
    isChoiceAdmin: boolean
) => {
    const availableForAdmin: ElementsType['children'] = [
        {
            label: 'Admins',
            key: admins.key,
            mode: SHOW_MODE.ALWAYS,
            permission: admins.permission,
            blocked: DeviceType.Tablet
        },
        {
            label: 'Users',
            key: users.key,
            mode: SHOW_MODE.ALWAYS,
            permission: users.permission,
            blocked: DeviceType.Tablet
        },
        {
            label: 'Profiles',
            key: profiles.key,
            mode: SHOW_MODE.ALWAYS,
            permission: profiles.permission,
            blocked: DeviceType.Tablet
        }
    ];

    const availableForChoiceAdmin: ElementsType['children'] = [
        ...availableForAdmin,
        {
            label: 'Choice Administrators',
            key: choiceAdmins.key,
            mode: SHOW_MODE.ALWAYS,
            permission: choiceAdmins.permission,
            blocked: DeviceType.Tablet
        }
    ];

    if (withoutFlagsAndPermissions) {
        return availableForChoiceAdmin;
    }

    return isChoiceAdmin ? availableForChoiceAdmin : availableForAdmin;
};

export const getSideBarItems = ({
    userInfo,
    withoutFlagsAndPermissions = false,
    itemsWithPermissions = SideBarItemsWithPermissions
}: IGetSideBarItem): ElementsType[] => {
    const isChoiceAdmin = Boolean(userInfo?.isChoiceAdmin);

    const AdminPanelItem: ElementsType = {
        label: isChoiceAdmin ? 'Site Administration' : 'User Administration',
        key: 'adminPanel',
        icon: isChoiceAdmin ? <SvgSiteAdministration /> : <SvgUsers />,
        children: getAdminPanelChildren(
            withoutFlagsAndPermissions,
            isChoiceAdmin
        ),
        permission: MENU_SECTIONS_PERMISSION_KEYS.adminPanel,
        blocked: DeviceType.Tablet
    };
    const adminsItems = [...itemsWithPermissions, AdminPanelItem];

    if (withoutFlagsAndPermissions) {
        return adminsItems;
    }

    const featureFlags = FeatureFlagsController.getInstance();
    const isUser = userInfo?.isUser;
    const menuItems = isUser ? itemsWithPermissions : adminsItems;

    return featureFlags.getFormattedSiderMenu(menuItems);
};

export const getAllSideBarItems: GetAllSideBarType = (
    userInfo: UserTokenInfo | null,
    withoutFlagsAndPermissions = false
) => getSideBarItems({ userInfo, withoutFlagsAndPermissions });
