import React from 'react';

import { DateTime } from 'luxon';

import { DATE_FORMAT, TIME_FORMAT } from '@apis/users/UsersApi.types';
import {
    NullableDate,
    Period,
    PeriodVariants
} from '@customTypes/general/general.types';

export const getTimeInMinutes = (hours: number, minutes: number): number => {
    return hours * 60 + minutes;
};

export const getLowercaseDateFormat = ({
    dateFormatKey = DATE_FORMAT.MONTH_DAY_YEAR
}: {
    dateFormatKey?: DATE_FORMAT;
}) => {
    const isMonthDayYearFormat = dateFormatKey === DATE_FORMAT.MONTH_DAY_YEAR;
    const dateParts = isMonthDayYearFormat
        ? dateFormatKey.split('/')
        : dateFormatKey.split('.');

    const month = isMonthDayYearFormat ? dateParts[0] : dateParts[1];
    const day = isMonthDayYearFormat ? dateParts[1] : dateParts[0];
    const year = dateParts[2];
    const monthDayFormat = `${month}/${day.toLowerCase()}/${year.toLowerCase()}`;
    const dayMonthFormat = `${day.toLowerCase()}.${month}.${year.toLowerCase()}`;

    if (isMonthDayYearFormat) {
        return monthDayFormat;
    }

    return dayMonthFormat;
};

export const getMainDateFormat = ({
    dateFormatKey = DATE_FORMAT.MONTH_DAY_YEAR,
    timeFormatKey = TIME_FORMAT['12_HOURS']
}: {
    dateFormatKey?: DATE_FORMAT;
    timeFormatKey?: TIME_FORMAT;
}) => {
    const dateFormat =
        dateFormatKey === DATE_FORMAT.MONTH_DAY_YEAR
            ? 'MM/DD/YYYY'
            : 'DD.MM.YYYY';
    const monthDateFormat =
        dateFormatKey === DATE_FORMAT.MONTH_DAY_YEAR ? 'MMM d' : 'd MMM';
    const timeFormat =
        timeFormatKey === TIME_FORMAT['12_HOURS'] ? 'h:mm a' : 'HH:mm';

    const timeWithSeconds =
        timeFormatKey === TIME_FORMAT['12_HOURS'] ? 'h:mm:ss a' : 'HH:mm:ss';

    const monthDateAndYearFormat = `${monthDateFormat}, yyyy`;

    const weekDayMonthAndYear = `EEEE, ${monthDateAndYearFormat}`;

    return {
        dateFormat,
        monthDateFormat,
        weekDayMonthAndYear,
        dateTimeFormat: `${monthDateFormat}, ${timeFormat}`,
        monthDateAndYearFormat: monthDateAndYearFormat,
        monthDateAndYearWithTimeFormat: `${monthDateAndYearFormat}, ${timeFormat}`,
        timeWithSeconds
    };
};

function getHoursAndMinutes(
    timeInMinutes: number,
    is24Hour = false
): { hours: number; mins: number; period?: Period } {
    const mins = timeInMinutes % 60;
    const hours = Math.floor(timeInMinutes / 60);

    if (is24Hour) {
        return { hours, mins };
    }

    if (hours > 11) {
        return {
            hours: hours === 12 ? 12 : hours - 12,
            mins,
            period: PeriodVariants.PM
        };
    }

    return { hours, mins, period: PeriodVariants.AM };
}

export function formatTimeToString(
    timeInMinutes: number,
    showPeriod = true,
    is24Format = false
): string {
    const { hours, mins, period } = getHoursAndMinutes(
        timeInMinutes,
        is24Format
    );

    const formattedTime = DateTime.local()
        .set({ minute: mins, hour: hours })
        .toFormat(!is24Format ? 'hh:mm' : 'HH:mm');

    return `${formattedTime}${showPeriod ? ` ${period}` : ''}`;
}

interface IOffsetReturn {
    date: string;
    monthAndDate: string;
    monthDateAndYear: string;
    monthDateYearAndTime: string;
    weekDayMonthAndYear: string;
    offset?: string;
    dateWithOffset?: string;
    isoDateWithOffset?: string;
}

interface IFormatDateProps {
    date: string;
    timeInMinutes?: number;
    offset?: string;
    dateFormatKey?: DATE_FORMAT;
    timeFormatKey?: TIME_FORMAT;
}

export function formatDateString({
    date,
    timeInMinutes,
    offset,
    dateFormatKey = DATE_FORMAT.MONTH_DAY_YEAR,
    timeFormatKey = TIME_FORMAT['12_HOURS']
}: IFormatDateProps): IOffsetReturn {
    const getDateStringToFormat = () => {
        if (timeInMinutes === undefined) {
            return DateTime.fromISO(date, { setZone: true });
        }

        if (!offset) {
            return DateTime.fromISO(date).set({ minute: timeInMinutes });
        }

        return DateTime.fromISO(date)
            .set({ minute: timeInMinutes })
            .setZone(offset, {
                keepLocalTime: true
            });
    };

    const dateStringWithoutOffset = getDateStringToFormat().toFormat(
        getMainDateFormat({ dateFormatKey, timeFormatKey }).dateTimeFormat
    );

    const monthAndDate = getDateStringToFormat().toFormat(
        getMainDateFormat({ dateFormatKey, timeFormatKey }).monthDateFormat
    );

    const monthDateAndYear = getDateStringToFormat().toFormat(
        getMainDateFormat({ dateFormatKey, timeFormatKey })
            .monthDateAndYearFormat
    );

    const monthDateYearAndTime = getDateStringToFormat().toFormat(
        getMainDateFormat({ dateFormatKey, timeFormatKey })
            .monthDateAndYearWithTimeFormat
    );

    const offsetFormatted = getDateStringToFormat().zone.formatOffset(
        getDateStringToFormat().offset,
        'short'
    );

    const weekDayMonthAndYear = getDateStringToFormat().toFormat(
        getMainDateFormat({ dateFormatKey, timeFormatKey }).weekDayMonthAndYear
    );

    return {
        date: dateStringWithoutOffset,
        monthAndDate,
        monthDateAndYear,
        monthDateYearAndTime,
        weekDayMonthAndYear,
        offset: offsetFormatted,
        dateWithOffset: `${dateStringWithoutOffset} (UTC${offsetFormatted})`,
        isoDateWithOffset: getDateStringToFormat().toISO()
    };
}

export const convertDateStringToJSFormat = (dateString: string): Date =>
    DateTime.fromISO(dateString).toJSDate();

export const getDateString = (date: NullableDate): string =>
    date ? DateTime.fromJSDate(date).toISODate() : '';

export const isSameJSDate = (d1?: Date, d2?: Date) => {
    if (!d1 || !d2) return;
    return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
    );
};

export const getDateAndTimeToDisplay = ({
    showDatesInRow = false,
    dateTime,
    timeZone,
    isCourier = true
}: {
    showDatesInRow?: boolean;
    dateTime?: string;
    timeZone?: string;
    isCourier?: boolean;
}) =>
    showDatesInRow ? (
        `${dateTime} (${timeZone})`
    ) : (
        <div>
            <div>{`${dateTime?.split(', ')[0]}${isCourier ? ',' : ''}`}</div>
            {isCourier && (
                <div>{`${dateTime?.split(', ')[1]} (${timeZone})`}</div>
            )}
        </div>
    );
