import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { isMobileAndTablet } from '@constants/breakpoints';
import { RoutesList } from '@constants/routesList';
import { GoToOrderDto } from '@customTypes/notifications/notifications.type';
import { useWindowDimentions } from '@hooks/useWindowDimentions';
import { useSignalR } from '@signalR/SignalR';
import { EVENTS, HUBS } from '@signalR/types';
import { useAppDispatch } from '@store/index';
import { resetNotificationsState } from '@store/slices/notifications/notifications';
import {
    useGetNotificationsMutation,
    useLazyGetUnreadCountQuery,
    useReadAllNotificationsMutation,
    useReadSingleNotificationMutation
} from 'APIServices/notifications/Notifications.api';
import { SvgBellDark, SvgBellLight } from 'components/icons';
import { Badge } from 'components/UI/atoms/badge/Badge';
import { NotificationsPanel } from 'components/UI/organisms/notificationsPanel/NotificationsPanel';

import styles from './Notifications.module.less';

const DEFAULT_REQUEST_PAYLOAD = {
    sorts: '-CreatedDate',
    page: 1,
    pageSize: 5
};

export const Notifications = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { width } = useWindowDimentions();
    const isMobileVersion = isMobileAndTablet(width);

    const [isOpen, setIsOpen] = useState(false);
    const [getUnreadCount] = useLazyGetUnreadCountQuery();
    const [readAllNotifications] = useReadAllNotificationsMutation();
    const [readSingleNotification] = useReadSingleNotificationMutation();
    const [getNotifications] = useGetNotificationsMutation();

    const [unreadCounter, setUnreadCounter] = useState<number | undefined>(
        undefined
    );

    const { openConnection } = useSignalR<number>({
        hub: HUBS.user.notificationCount,
        eventCallBack: (unreadCount) => {
            setUnreadCounter(unreadCount);
        },
        eventName: EVENTS.RECEIVE_UNREAD_ORDER_STATUS_NOTIFICATIONS_COUNT
    });

    useEffect(() => {
        getUnreadCount()
            .unwrap()
            .then((unreadCountResponse) => {
                setUnreadCounter(unreadCountResponse.data);
            });
        openConnection();
    }, []);

    const markSingleNotificationAsRead = (
        notificationId: string,
        isRead: boolean
    ) => {
        readSingleNotification(notificationId.toString());
        if (unreadCounter && !isRead) {
            setUnreadCounter(unreadCounter - 1);
        }
    };

    const goToDetailsPage = ({
        orderId,
        notificationId,
        isRead,
        proNum
    }: GoToOrderDto) => {
        dispatch(resetNotificationsState());
        setIsOpen(false);
        markSingleNotificationAsRead(notificationId.toString(), isRead);

        const path = RoutesList.TRACK_ORDERS.ORDER_DETAILS.VIEW_FULL.replace(
            ':id',
            String(orderId)
        );

        navigate(`/${path}`, {
            state: { proNum }
        });
    };

    const markAllAsRead = async () => {
        dispatch(resetNotificationsState());
        await readAllNotifications();
        await getNotifications(DEFAULT_REQUEST_PAYLOAD);
        setUnreadCounter(0);
    };

    return (
        <div
            className={styles.NotificationsContainer}
            data-testid="notificationsContainer"
        >
            <NotificationsPanel
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                goToDetailsPage={goToDetailsPage}
                markAllAsRead={markAllAsRead}
                unreadCounter={unreadCounter}
            >
                <div
                    id="badge"
                    data-testid="counterBadge"
                    className={classNames(styles.Row, {
                        [styles.Badge__Empty]: !unreadCounter
                    })}
                >
                    <Badge
                        offset={[-4, 10]}
                        overflowCount={999}
                        count={unreadCounter}
                        node={
                            isMobileVersion ? <SvgBellLight /> : <SvgBellDark />
                        }
                    />
                </div>
            </NotificationsPanel>
        </div>
    );
};
