import React, { ForwardedRef } from 'react';

import { Input, InputProps, InputRef } from 'antd';
import classNames from 'classnames';

import { VoidFunction } from '@customTypes/general/general.types';
import { SvgCross, SvgSearch } from 'components/icons';

import styles from './Input.module.less';

export interface IInput extends Omit<InputProps, 'onChange'> {
    isSearch?: boolean;
    hasError?: boolean;
    withCross?: boolean;
    onClear?: VoidFunction;
    innerRef?: ForwardedRef<InputRef>;
    normalize?: (value: string) => string;
    onChange?: (value: string) => void;
    className?: string;
}

const defaultNormalize = (value: string) => value;

export const TextInput = ({
    isSearch,
    withCross,
    onClear,
    disabled = false,
    hasError = false,
    prefix = null,
    suffix,
    innerRef,
    onChange,
    normalize = defaultNormalize,
    className,
    ...props
}: IInput) => {
    const defaultSuffix = withCross && !disabled && (
        <SvgCross
            onClick={onClear}
            className={styles.Suffix}
            data-testid="clear"
        />
    );

    const normalizedValue = normalize(props.value?.toString() || '');

    return (
        <div className={styles.Container}>
            <Input
                {...props}
                value={normalizedValue}
                ref={innerRef}
                prefix={
                    isSearch ? (
                        <SvgSearch
                            className={styles.Input__Prefix}
                            color={disabled ? '#8E94A9' : ''}
                            data-testid="search icon"
                        />
                    ) : (
                        <>{prefix}</>
                    )
                }
                onChange={(e) => {
                    onChange?.(normalize(e.target.value));
                }}
                suffix={suffix || defaultSuffix}
                className={classNames(styles.Input, className)}
                status={hasError ? 'error' : ''}
                disabled={disabled}
            />
        </div>
    );
};
