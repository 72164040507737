import React from 'react';

import { Label, LabelVariants } from '@atoms/label/Label';

interface IProps {
    isDefective?: boolean;
    hideLabel?: boolean;
}

export const GoodDefectiveLabel = ({
    isDefective,
    hideLabel = false
}: IProps) => {
    if (hideLabel) return null;
    return isDefective ? (
        <Label variant={LabelVariants.RED} name="Defective" />
    ) : (
        <Label variant={LabelVariants.GREEN} name="Good" />
    );
};
