import { useEffect, useState } from 'react';

import {
    HubConnection,
    HubConnectionBuilder,
    IHttpConnectionOptions
} from '@microsoft/signalr';

import AuthApi from '@apis/auth/auth.api';
import { AppController } from '@configs/AppController';
import { EVENTS } from 'signalR/types';

interface Args<T> {
    hub: string;
    eventCallBack: (data: T) => void;
    eventName: EVENTS;
}

export const useSignalR = <T>({ hub, eventCallBack, eventName }: Args<T>) => {
    const socketsPath = AppController.getInstance().config?.api_sockets;
    const options: IHttpConnectionOptions = {
        accessTokenFactory: () => AuthApi.getAccessToken()
    };

    const [connection, setConnection] = useState<HubConnection | null>(null);

    const openConnection = () => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${socketsPath}/hubs/${hub}`, options)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    };

    const closeConnection = () => {
        if (connection) {
            connection?.stop();
        }
    };

    useEffect(() => {
        if (connection) {
            connection.start().then(() => {
                connection.on(eventName, (message: T) => {
                    eventCallBack(message);
                });
            });
        }

        return () => {
            connection?.stop();
        };
    }, [connection]);

    return {
        openConnection,
        closeConnection
    };
};
