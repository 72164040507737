import React, { useEffect, useState } from 'react';

import { Radio } from 'antd';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { RadioGroup } from '@atoms/radio/Radio';
import { orderDateFilterTypesData } from '@constants/trackOrdersMockedData';
import { convertDateStringToJSFormat } from '@helpers/dateAndTimeFormatters';
import { useUserSettings } from '@hooks/useUserSettings';
import { Filter, IFilterProps } from '@molecules/filters/filter/Filter';
import {
    RangeNullableDates,
    RangePicker
} from '@molecules/rangePicker/RangePicker';
import { checkIfDefaultPeriodSelected } from '@organisms/trackOrders/orderList/ordersListUrlUtils';
import { SvgCalendar } from 'components/icons';

import styles from './DateRangeFilter.module.less';

interface IProps extends Partial<IFilterProps> {
    defaultDateRange: number;
    minDate: Date;
    from: string;
    to: string;
    onChangeDates: (dates: RangeNullableDates, dateType: string) => void;
    onResetPeriod: VoidFunction;
    dateType: string;
}

type IRangeRadioProps = RadioButtonProps;

const RangeRadio = ({ value, name, ...props }: IRangeRadioProps) => (
    <Radio.Button
        {...props}
        value={value}
        className={classNames(styles.Radio, {
            [styles.Radio__Selected]: props.checked
        })}
        data-testid="range-radio-button"
    >
        <div className={styles.Radio__InsideBlock}>
            <span className={styles.Radio__Title}>{name}</span>
        </div>
    </Radio.Button>
);

export const DateRangeFilter = ({
    defaultDateRange,
    minDate,
    from,
    to,
    onChangeDates,
    onResetPeriod,
    dateType,
    ...props
}: IProps) => {
    const [openedOutside, setOpenedOutside] = useState(false);
    const [isApplyDisabled, setIsApplyDisabled] = useState(true);
    const [selectedDates, setSelectedDates] = useState<RangeNullableDates>([
        null,
        null
    ]);

    const [rangeType, setRangeType] = useState<string>(
        orderDateFilterTypesData[0].value
    );

    const { dateFormatKey } = useUserSettings();

    const defaultDateFrom = DateTime.now()
        .minus({ days: defaultDateRange - 1 })
        .toISODate();
    const defaultDateTo = DateTime.now().toISODate();

    const checkDates = (selectedRangeDates: RangeNullableDates) => {
        const dateFrom = selectedRangeDates[0]
            ? DateTime.fromJSDate(selectedRangeDates[0])
            : null;

        const dateTo = selectedRangeDates[1]
            ? DateTime.fromJSDate(selectedRangeDates[1])
            : null;

        const currentDateFrom = DateTime.fromISO(from);
        const currentDateTo = DateTime.fromISO(to);
        const dateFromIsNew = dateFrom && !dateFrom.equals(currentDateFrom);
        const dateToIsNew = dateTo && !dateTo.equals(currentDateTo);

        return !selectedRangeDates[1] ? false : dateFromIsNew || dateToIsNew;
    };

    useEffect(() => {
        const isRangeTypeChanged = rangeType !== dateType;
        const isDateChanged = checkDates(selectedDates);

        const applyIsDisabled = isRangeTypeChanged || isDateChanged;

        setIsApplyDisabled(!applyIsDisabled);
    }, [rangeType]);

    const onOpenFilter = () => {
        setSelectedDates([
            convertDateStringToJSFormat(from),
            convertDateStringToJSFormat(to)
        ]);
        setRangeType(dateType);
    };

    const isNotDefaultPeriod = !checkIfDefaultPeriodSelected({
        defaultDateRangeDays: defaultDateRange,
        from,
        to,
        dateType
    });

    const handleChangeDates = (selectedRangeDates: RangeNullableDates) => {
        const isRangeTypeChanged = rangeType !== dateType;
        const dateFrom = selectedRangeDates[0]
            ? DateTime.fromJSDate(selectedRangeDates[0])
            : null;

        const dateTo = selectedRangeDates[1]
            ? DateTime.fromJSDate(selectedRangeDates[1])
            : null;

        setSelectedDates([selectedRangeDates[0], selectedRangeDates[1]]);

        const isDateAndRangeChanged =
            isRangeTypeChanged && dateFrom && dateTo
                ? true
                : checkDates(selectedRangeDates);

        setIsApplyDisabled(!isDateAndRangeChanged);
    };

    const handleApplyDates = () => {
        onChangeDates(selectedDates, rangeType);
        setIsApplyDisabled(true);
    };

    const handleReset = () => {
        onResetPeriod();
        setOpenedOutside(false);
    };

    return (
        <Filter
            {...props}
            destroyOnClose={true}
            icon={<SvgCalendar color="#273c83" />}
            filterName="Date Range"
            dataTestId="DateRangeFilter"
            isSelected={isNotDefaultPeriod}
            onApply={handleApplyDates}
            onOpenFilter={onOpenFilter}
            openedOutside={openedOutside}
            setOpenedOutside={setOpenedOutside}
            isApplyDisabled={isApplyDisabled}
            mainFilterContent={
                <div className={styles.Container}>
                    <div className={styles.Radio__Group}>
                        <RadioGroup
                            value={rangeType}
                            onChange={({ target }) => {
                                setRangeType(target.value);
                                setSelectedDates([
                                    convertDateStringToJSFormat(
                                        defaultDateFrom
                                    ),
                                    convertDateStringToJSFormat(defaultDateTo)
                                ]);
                            }}
                        >
                            {orderDateFilterTypesData.map(
                                ({ value, label }) => (
                                    <RangeRadio
                                        value={value}
                                        name={label}
                                        checked={value === rangeType}
                                        key={value}
                                    />
                                )
                            )}
                        </RadioGroup>
                    </div>
                    <RangePicker
                        minDate={minDate}
                        fromValue={selectedDates[0]}
                        toValue={selectedDates[1]}
                        isNotDefaultPeriod={isNotDefaultPeriod}
                        onResetPeriod={handleReset}
                        onChange={handleChangeDates}
                        maxDate={new Date()}
                        dateFormatKey={dateFormatKey}
                    />
                </div>
            }
        />
    );
};
