import React, { ReactNode } from 'react';

import cn from 'classnames';

import { Button } from '@atoms/button/Button';
import { ScreenBlock } from '@atoms/screenBlock/ScreenBlock';
import { VoidFunction } from '@customTypes/general/general.types';
import { ScreenContainer } from '@molecules/screenContainer/ScreenContainer';
import { Head } from '@organisms/formBlock/head/Head';

import styles from './PanelBlock.module.less';

interface IProps {
    title?: string | ReactNode;
    description?: string | ReactNode;
    fullHeight?: boolean;
    fullWidth?: boolean;
    children: ReactNode;
    actionText?: string;
    actionContent?: ReactNode;
    className?: string;
    contentContainerClassName?: string;
    contentClassName?: string;
    withDivider?: boolean;
    withBackButton?: boolean;
    hideHeaderOnMobile?: boolean;
    onActionClick?: VoidFunction;
    isFullWidthOnSmallDesktop?: boolean;
}

export const PanelBlock = ({
    title,
    description,
    fullHeight,
    fullWidth = true,
    children,
    actionText,
    actionContent,
    className,
    contentContainerClassName,
    contentClassName,
    withDivider,
    withBackButton,
    hideHeaderOnMobile,
    onActionClick,
    isFullWidthOnSmallDesktop
}: IProps) => {
    return (
        <ScreenContainer className={className}>
            <div
                className={cn(styles.Container, {
                    [styles.Container__MinWidth]: !fullWidth,
                    [styles.Container__FullWidthOnSmallDesktop]:
                        isFullWidthOnSmallDesktop
                })}
                data-testid="Container"
            >
                <ScreenBlock
                    contentClassName={contentContainerClassName}
                    fullHeight={fullHeight}
                    fullWidth={fullWidth}
                    withTopMargin={false}
                >
                    <div className={cn(styles.Gap, contentClassName)}>
                        {title && (
                            <Head
                                title={title}
                                hideOnMobile={hideHeaderOnMobile}
                                description={description}
                                withDivider={withDivider}
                                withBackButton={withBackButton}
                            >
                                {actionContent}
                                {actionText && (
                                    <Button
                                        text={actionText}
                                        onClick={onActionClick}
                                        theme="primary"
                                        data-testid="ActionButton"
                                    />
                                )}
                            </Head>
                        )}
                        {children}
                    </div>
                </ScreenBlock>
            </div>
        </ScreenContainer>
    );
};
