import React, { useState } from 'react';

import classNames from 'classnames';
import { DateTime } from 'luxon';

import { DATE_FORMAT, TIME_FORMAT } from '@apis/users/UsersApi.types';
import { Button } from '@atoms/button/Button';
import { IInput, TextInput } from '@atoms/input/Input';
import { Popover } from '@atoms/popover/Popover';
import {
    datePickerDaysPlaceholder,
    datePickerMonthPlaceholder
} from '@constants/datePicker';
import { NullableDate } from '@customTypes/general/general.types';
import { getLowercaseDateFormat } from '@helpers/dateAndTimeFormatters';
import { DatePicker, IDatePickerProps } from '@molecules/datePicker/DatePicker';
import { SvgArrowFullRight, SvgCalendar } from 'components/icons';

import styles from './RangePicker.module.less';

export type RangeNullableDates = [NullableDate, NullableDate];

interface IProps extends IDatePickerProps<true> {
    minDate?: Date;
    customClassName?: string;
    fromValue: NullableDate;
    toValue: NullableDate;
    isNotDefaultPeriod?: boolean;
    onResetPeriod?: VoidFunction;
    openedOutside?: boolean;
    openByClick?: boolean;
    dateFormatKey?: DATE_FORMAT;
    timeFormatKey?: TIME_FORMAT;
}

const RangePickerInput = ({
    value,
    name,
    placeholder,
    suffix,
    ...props
}: IInput) => {
    return (
        <div
            className={styles.Input__Label}
            data-testid="range-picker-input-label"
        >
            <div className={styles.DatePicker__Label}>{name}</div>
            <TextInput
                {...props}
                name={name}
                placeholder={placeholder}
                suffix={suffix}
                readOnly
                value={value}
                data-testid="range-picker-input"
            />
        </div>
    );
};

export const RangePicker = ({
    minDate,
    customClassName,
    fromValue,
    onChange,
    toValue,
    isNotDefaultPeriod,
    onResetPeriod,
    openByClick,
    dateFormatKey = DATE_FORMAT.MONTH_DAY_YEAR,
    timeFormatKey = TIME_FORMAT['12_HOURS'],
    ...props
}: IProps) => {
    const [isOpenOverlay, setIsOpenOverlay] = useState(false);

    const datePickerPlaceholder =
        dateFormatKey === DATE_FORMAT.MONTH_DAY_YEAR
            ? datePickerMonthPlaceholder
            : datePickerDaysPlaceholder;

    const convertDateToDateString = (dateValue: NullableDate) => {
        return (
            dateValue &&
            DateTime.fromJSDate(dateValue).toFormat(
                getLowercaseDateFormat({ dateFormatKey })
            )
        );
    };

    const onClickDateRange = () => {
        setIsOpenOverlay((prevState) => !prevState);
    };

    const rangeInputSection = () => {
        return (
            <div className={styles.Input} onClick={onClickDateRange}>
                <RangePickerInput
                    name="Date From"
                    value={convertDateToDateString(fromValue) || ''}
                    className={styles.Input__From}
                    placeholder={datePickerPlaceholder}
                    suffix={
                        <SvgArrowFullRight
                            color="#8e94a9"
                            data-testid="arrow icon"
                        />
                    }
                />
                <RangePickerInput
                    name="Date To"
                    value={convertDateToDateString(toValue) || ''}
                    placeholder={datePickerPlaceholder}
                    suffix={<SvgCalendar data-testid="calendar icon" />}
                    className={styles.Input__To}
                />
            </div>
        );
    };

    const spawnPicker = () => {
        return (
            <DatePicker
                {...props}
                minDate={minDate}
                selected={fromValue}
                onChange={onChange}
                startDate={fromValue}
                endDate={toValue}
                value={fromValue}
                selectsRange
                inline
                monthsShown={2}
                headerClassName={styles.DateHeader}
                customInputPlaceholder={datePickerPlaceholder}
            />
        );
    };

    return (
        <div className={styles.Container}>
            {openByClick && (
                <Popover
                    destroyTooltipOnHide={true}
                    visible={isOpenOverlay}
                    onVisibleChange={(newOpen: boolean) => {
                        setIsOpenOverlay(newOpen);
                    }}
                    trigger="click"
                    triggerContent={
                        <div className={styles.Content}>
                            {rangeInputSection()}
                            <div
                                className={styles.HintAllFilters}
                                data-testid="range-picker-hint"
                            >
                                {isNotDefaultPeriod && (
                                    <div className={styles.Hint}>
                                        <Button
                                            theme="link"
                                            text="Reset"
                                            className={styles.Hint__Button}
                                            onClick={onResetPeriod}
                                        />
                                        to default
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    showIcon={false}
                    overlayClass={styles.OverlayClass}
                    popoverContent={
                        <>
                            <div
                                className={classNames(
                                    styles.PopoverDatePicker,
                                    customClassName
                                )}
                            >
                                {spawnPicker()}
                            </div>
                        </>
                    }
                />
            )}

            {!openByClick && (
                <>
                    {rangeInputSection()}
                    {spawnPicker()}
                    <div
                        className={styles.Hint}
                        data-testid="range-picker-hint"
                    >
                        {isNotDefaultPeriod && (
                            <div className={styles.Hint}>
                                <Button
                                    theme="link"
                                    text="Reset"
                                    className={styles.Hint__Button}
                                    onClick={onResetPeriod}
                                />
                                to default
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
