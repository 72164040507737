import React, { ReactNode } from 'react';

import { Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import { Button } from '@atoms/button/Button';
import { Loader } from '@atoms/loader/Loader';
import { Popover } from '@atoms/popover/Popover';
import { GoToOrderDto } from '@customTypes/notifications/notifications.type';
import { useUserSettings } from '@hooks/useUserSettings';
import { CardContainer } from '@organisms/notificationsPanel/shared/сardContainer/CardContainer';
import { useNotificationPanel } from '@organisms/notificationsPanel/useNotificationPanel';

import styles from './NotificationsPanel.module.less';

interface IProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
    goToDetailsPage: (data: GoToOrderDto) => void;
    markAllAsRead: () => void;
    children: ReactNode;
    unreadCounter?: number;
}

export const NotificationsPanel = ({
    isOpen,
    setIsOpen,
    goToDetailsPage,
    markAllAsRead,
    children,
    unreadCounter
}: IProps) => {
    const {
        totalCount,
        loading,
        notifications,
        handleLoadMore,
        handleOpenChange
    } = useNotificationPanel({ isOpen, setIsOpen });
    const { dateFormatKey } = useUserSettings();
    const showLoader = !notifications.length && loading;

    return (
        <Popover
            className={styles.NotificationsPanel}
            overlayClass={styles.NotificationsPanelOverlay}
            align={{ offset: [70, 0] }}
            popoverContent={
                <div
                    data-testid="NotificationsPanelContent"
                    className={styles.Content}
                >
                    {showLoader ? (
                        <div className={styles.LoaderContainer}>
                            <Loader
                                className={styles.Loader}
                                key={0}
                                size="medium"
                            />
                        </div>
                    ) : (
                        <>
                            {totalCount ? (
                                <InfiniteScroll
                                    pageStart={0}
                                    useWindow={false}
                                    loadMore={handleLoadMore}
                                    hasMore={notifications.length < totalCount}
                                    loader={
                                        <Loader
                                            className={styles.Loader}
                                            key={0}
                                            size="medium"
                                        />
                                    }
                                >
                                    {notifications.map((item) => (
                                        <CardContainer
                                            key={item[0].userNotificationId}
                                            data={item}
                                            goToDetailsPage={goToDetailsPage}
                                            dateFormatKey={dateFormatKey}
                                        />
                                    ))}
                                </InfiniteScroll>
                            ) : (
                                <div className={styles.NoDataContainer}>
                                    <div data-testid="NoDataMessage">
                                        No Notifications
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            }
            title={
                <div
                    data-testid="NotificationsPanelHeader"
                    className={styles.Header}
                >
                    <div className={styles.Header__Content}>
                        <div
                            data-testid="HeaderTitle"
                            className={styles.Header__Title}
                        >
                            Notifications
                        </div>
                        <Button
                            testId="MarkAllAsReadButton"
                            disabled={!totalCount || unreadCounter === 0}
                            text="Mark All as Read"
                            theme="link"
                            onClick={markAllAsRead}
                        />
                    </div>
                    <Divider className={styles.Divider} />
                </div>
            }
            showArrow={false}
            showIcon={false}
            placement="bottom"
            trigger="click"
            visible={isOpen}
            onVisibleChange={handleOpenChange}
            triggerContent={
                <div className={styles.TriggerContent}>{children}</div>
            }
            destroyTooltipOnHide={true}
        />
    );
};
