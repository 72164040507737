import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { useGoBack } from '@hooks/useNavigationHelpers';
import { SvgArrowFullRight } from 'components/icons';

import styles from './Head.module.less';

interface IProps {
    title?: string | ReactNode;
    description?: string | ReactNode;
    titleToolTip?: ReactNode;
    children?: ReactNode;
    withDivider?: boolean;
    hideOnMobile?: boolean;
    withBackButton?: boolean;
}

export const Head = ({
    title,
    description,
    children,
    withDivider,
    hideOnMobile = true,
    withBackButton,
    titleToolTip
}: IProps) => {
    const goBack = useGoBack();
    const withDividerRule = Boolean(description) || withDivider;

    return (
        <div
            className={classNames(styles.Head, {
                [styles.Head__Divider]: withDividerRule,
                [styles.Head__Hide]: hideOnMobile
            })}
            id="PanelBlockTitle"
        >
            <div
                className={classNames(styles.Head__Info, {
                    [styles.Head__Info__WithDivider]: withDividerRule
                })}
            >
                <div className={styles.Head__TitleBlock}>
                    {withBackButton && (
                        <div className={styles.Back__Icon} onClick={goBack}>
                            <SvgArrowFullRight
                                color="#273C83"
                                transform="scale(1.5)"
                            />
                        </div>
                    )}
                    {title && (
                        <div className={styles.Head__TitleWithTooltip}>
                            <span className={styles.Head__Title}>{title}</span>
                            {Boolean(titleToolTip) && (
                                <div className={styles.Head__Tooltip}>
                                    {titleToolTip}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {description && (
                    <div
                        className={styles.Head__Description}
                        data-testid="header_description"
                    >
                        {description}
                    </div>
                )}
            </div>
            {children && <div className={styles.Head__Action}>{children}</div>}
        </div>
    );
};
