import {
    useGetUserInfoQuery,
    useGetUserSettingsQuery
} from 'APIServices/users/Users.api';
import { UserInfo, UserSettings } from 'APIServices/users/UsersApi.types';
import { useAppSelector } from 'store';

export interface UserSettingsData
    extends Partial<UserSettings>,
        Partial<UserInfo> {
    loading: boolean;
    isUserError?: boolean;
}

export const useUserSettings = (): UserSettingsData => {
    const userId = useAppSelector(({ user: { userId } }) => userId);
    const { data: userSettings, isLoading: userSettingsLoading } =
        useGetUserSettingsQuery();
    const { data: userData, isLoading: userDataLoading } = useGetUserInfoQuery(
        userId || ''
    );

    const isUserError = !userData || !userData?.data;

    return {
        ...(userData?.data || {}),
        ...(userSettings?.data || {}),
        isUserError,
        loading: userSettingsLoading || userDataLoading
    };
};
