import React from 'react';

import { Button } from '@atoms/button/Button';
import { EmptyState } from '@atoms/emptyState/EmptyState';
import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';
import { SvgSideChoiceLogo } from '@icons/SideChoiceLogo';

import styles from './ErrorFallback.module.less';

interface IProps {
    resetErrorBoundary: VoidFunction;
    isTryAgainLoading?: boolean;
    title?: string;
}

export const ErrorFallback = ({
    resetErrorBoundary,
    isTryAgainLoading,
    title
}: IProps) => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.ERROR_FALLBACK);

    return (
        <EmptyState
            title={title || 'Something went wrong'}
            className={styles.Container}
        >
            <Button
                text="Try again"
                className={styles.Container__HomeButton}
                startIcon={<SvgSideChoiceLogo />}
                theme="primary"
                onClick={resetErrorBoundary}
                isLoading={isTryAgainLoading}
            />
        </EmptyState>
    );
};
