import { createSlice } from '@reduxjs/toolkit';

import {
    materialsTransferByWarehouseApi,
    materialsTransferByWarehouseDefectiveApi
} from '@apis/materialsTransferByWarehouse/MaterialsTransferByWarehouse.api';
import { outboundOrderByPartApi } from '@apis/outboundOrderByPart/OutboundOrderByPart.api';
import {
    outboundOrderByWarehouseApi,
    outboundOrderByWarehouseDefectiveApi
} from '@apis/outboundOrderByWarehouse/OutboundOrderByWarehouse.api';
import { initialPaginationState } from '@constants/pagination';
import {
    MasterInitialState,
    PreCheckResponse
} from '@customTypes/api/api.types';
import { Pagination } from '@customTypes/general/general.types';
import { handleFulfilledPagination } from '@helpers/handleFulfilledPagination';
import { IAction } from 'store/index';

export interface IInitialPreCheckMaster extends MasterInitialState {
    data: PreCheckResponse['data']['items'];
}

export const initialPreCheckPagination: Pagination = {
    ...initialPaginationState,
    pageSize: 11
};

const initialState: IInitialPreCheckMaster = {
    loading: false,
    pagination: initialPreCheckPagination,
    errors: { code: 0, message: '' },
    data: []
};

export const preCheckMasterSlice = createSlice({
    name: 'preCheckMaster',
    initialState,
    reducers: {
        editPagination(
            state,
            action: IAction<
                Partial<Omit<Pagination, 'pagesCount' | 'totalItemsCount'>>
            >
        ) {
            state.pagination = {
                ...state.pagination,
                ...action.payload
            };
        },
        resetPagination(state) {
            state.pagination = initialPreCheckPagination;
        },
        resetPreCheckMaster() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            outboundOrderByPartApi.endpoints.preCheckByPart.matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            outboundOrderByWarehouseApi.endpoints.preCheckByWarehouse
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            outboundOrderByWarehouseDefectiveApi.endpoints.preCheckByWarehouse
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseApi.endpoints
                .preCheckTransferByWarehouse.matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseDefectiveApi.endpoints
                .preCheckTransferByWarehouse.matchFulfilled,
            handleFulfilledPagination
        );
    }
});

export const { editPagination, resetPagination, resetPreCheckMaster } =
    preCheckMasterSlice.actions;
