import { RefObject, useEffect, useState } from 'react';

import { BaseOptionType } from 'antd/lib/select';

import { Account, SubAccount } from '@apis/users/UsersApi.types';
import { FormattedTreeData } from '@customTypes/mirAndTracking/MirAndTracking.types';
import { areUniqueArraysEqual } from '@helpers/array';
import { capitalize } from '@helpers/stringHelper';
import { useTreeData } from '@hooks/useTreeData';
import { useUser } from '@hooks/useUser';
import {
    IComparedFilterValues,
    ISelectedFilters
} from '@organisms/allFiltersWrapper/AllFiltersWrapper';

interface TreeFilterReturnType {
    accounts: Account[] | undefined;
    accountsLoading: boolean;
    onTreeInit: VoidFunction;
    selectedKeys: string[];
    onClearClick?: (newSelectedKeys: string[]) => void;
    areSelectedKeysChanged: boolean;
    handleChange: (newValue: string[]) => void;
    allTreeFilterHandleChange: (newValue: string[]) => void;
    onRemoveSearchBadge: (value: string) => void;
    onRemoveFilterSearchBadge: (value: string) => void;
    treeData?: BaseOptionType[];
    popupRef: RefObject<HTMLDivElement>;
    getCustomPopupContainer: (triggerNode: HTMLElement) => HTMLElement;
    hasKeys?: boolean;
}

export const getSubAccountDescription = (
    id: number,
    data?: SubAccount[]
): string => {
    if (data?.length) {
        return data.find((item) => item.subAccountId === id)?.description || '';
    }
    return '';
};

export const getSubAccountIdBySubAccNumber = (
    subAccountNumber: string,
    data?: SubAccount[]
) => {
    if (data?.length) {
        return (
            data.find((item) => item.subAccountNumber === subAccountNumber)
                ?.subAccountId || null
        );
    }
    return null;
};

export const useAccountFilter = ({
    selectedFilterItems,
    isDefaultKeysDependency,
    setComparedResults,
    setNewFilters,
    selectInitialValue,
    data,
    filterType,
    compareType,
    isAccountAndSubsSetting,
    withCode = true
}: {
    selectedFilterItems: string[];
    isDefaultKeysDependency?: boolean;
    setComparedResults?: (newComparedValue: IComparedFilterValues) => void;
    setNewFilters?: (newFilter: ISelectedFilters) => void;
    selectInitialValue?: string[];
    data?: FormattedTreeData[];
    filterType?: string;
    compareType?: string;
    isAccountAndSubsSetting: boolean;
    withCode?: boolean;
}): TreeFilterReturnType => {
    const { accounts, accountsLoading = false, fetchAccounts } = useUser();

    const [treeData, setTreeData] = useState<
        {
            title: string;
            value: string;
            children: { title: string; value: string }[];
        }[]
    >([]);

    const getTreeData = async () => {
        if (isAccountAndSubsSetting) {
            const accounts = await fetchAccounts?.(true);

            const accountsData = accounts ? accounts.data : null;

            const dataByAccounts = accountsData
                ? accountsData.map(
                      ({ accountNumber, accountId, subAccounts }) =>
                          ({
                              title: accountNumber,
                              value: accountId.toString(),
                              children: subAccounts.map((el) => {
                                  const value = `${accountId}-${el.subAccountId}`;

                                  return {
                                      title: el.description,
                                      value
                                  };
                              })
                          } || [])
                  )
                : [];

            setTreeData(dataByAccounts);
        } else {
            const accountByAvailable = data?.map(
                ({
                    name: parentName,
                    code: parentCode,
                    children: parentChildren
                }) => ({
                    title: parentName,
                    value: parentCode,
                    children: parentChildren.map(({ name, code, children }) => {
                        const countryNameCapitalized = capitalize(name);

                        const sslListData = children.map(
                            (item) =>
                                ({
                                    title: `${
                                        withCode ? `${item.code}, ` : ''
                                    }${capitalize(item?.name || '')}${
                                        item?.description
                                            ? `, ${capitalize(
                                                  item?.description || ''
                                              )}`
                                            : ''
                                    }`,
                                    value: `${parentCode}_${code}_${item.code}`
                                } || [])
                        );

                        return {
                            title: countryNameCapitalized,
                            value: `${parentCode}_${code}`,
                            children: sslListData
                        };
                    })
                })
            );

            accountByAvailable && setTreeData(accountByAvailable);
        }
    };

    useEffect(() => {
        void getTreeData();
    }, [isAccountAndSubsSetting]);

    const {
        popupRef,
        getCustomPopupContainer,
        onTreeInit,
        handleChange,
        onRemoveSearchBadge,
        areSelectedKeysChanged,
        selectedKeys,
        onClearClick,
        hasKeys
    } = useTreeData({
        defaultSelectedKeys: selectedFilterItems,
        isDataLoading: accountsLoading,
        isDefaultKeysDependency
    });

    const onRemoveFilterSearchBadge = (value: string) => {
        const withoutRemoved = selectedKeys.filter((item) => item !== value);
        const isArraysEqual = areUniqueArraysEqual(
            selectInitialValue || [],
            withoutRemoved
        );
        setNewFilters?.({ [`${filterType}`]: withoutRemoved });
        setComparedResults?.({ [`${compareType}`]: isArraysEqual });
    };

    const allTreeFilterHandleChange = (newValue: string[]) => {
        handleChange(newValue);

        setNewFilters?.({ [`${filterType}`]: newValue });
        const isArraysEqual = areUniqueArraysEqual(
            selectInitialValue ?? [],
            newValue
        );
        setComparedResults?.({ [`${compareType}`]: isArraysEqual });
    };

    return {
        accounts,
        accountsLoading,
        areSelectedKeysChanged,
        onTreeInit,
        selectedKeys,
        onClearClick,
        handleChange,
        allTreeFilterHandleChange,
        onRemoveSearchBadge,
        onRemoveFilterSearchBadge,
        treeData,
        popupRef,
        hasKeys,
        getCustomPopupContainer
    };
};
