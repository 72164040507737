import React from 'react';

import { Col, Row } from 'antd';
import cn from 'classnames';

import { GoToOrderDto } from '@customTypes/notifications/notifications.type';

import styles from './CardContentRow.module.less';

export interface IProps {
    label: string;
    orderId: number | undefined;
    value: string | number;
    testId: string;
    isRead: boolean;
    proNumberAsLink?: boolean;
    goToDetailsPage?: (data: GoToOrderDto) => void;
    notificationId: number;
}

export const CardContentRow = ({
    label,
    value,
    orderId,
    proNumberAsLink,
    testId,
    isRead,
    goToDetailsPage,
    notificationId
}: IProps) => {
    const onClick = () => {
        if (!goToDetailsPage || !orderId || !notificationId) return;

        goToDetailsPage({
            orderId,
            isRead,
            notificationId,
            proNum: value.toString()
        });
    };

    return (
        <Row
            data-testid={testId}
            className={cn(styles.CardContentRow, {
                [styles.CardContentRow__Read]: isRead
            })}
        >
            <Col
                data-testid={`${testId}Label`}
                className={styles.CardContentRow__Label}
            >
                {label}:
            </Col>
            <Col
                data-testid={`${testId}Value`}
                className={styles.CardContentRow__Value}
            >
                {proNumberAsLink ? (
                    <span
                        className={cn(styles.ProNumberLink, {
                            [styles.ProNumberLink__Read]: isRead
                        })}
                        onClick={onClick}
                    >
                        {value}
                    </span>
                ) : (
                    <span
                        className={cn(styles.Value, {
                            [styles.Value__Read]: isRead
                        })}
                    >
                        {value}
                    </span>
                )}
            </Col>
        </Row>
    );
};
