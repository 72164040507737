import React from 'react';

import classNames from 'classnames';

import { Button } from '@atoms/button/Button';
import styles from '@atoms/refresh/Refresh.module.less';
import { VoidFunction } from '@customTypes/general/general.types';
import { SvgRefresh } from '@icons/Refresh';

interface IProps {
    onRefreshClick: VoidFunction;
    lastUpdated?: string;
    isMobile?: boolean;
    reverse?: boolean;
    watchListMode?: boolean;
    isDarkModeEnabled?: boolean;
}

export const Refresh = ({
    lastUpdated,
    onRefreshClick,
    isMobile,
    reverse = false,
    watchListMode = false,
    isDarkModeEnabled = false
}: IProps) => {
    return (
        <div
            className={classNames(styles.Info, {
                [styles.Info__Reversed]: reverse,
                [styles.Info__WatchListMode]: isMobile && watchListMode
            })}
            id="OrderStatusLabelUpdated"
        >
            <div
                className={classNames(styles.Info__Updated, {
                    [styles.Info__Updated__WatchListMode]:
                        isMobile && watchListMode
                })}
            >
                {lastUpdated}
            </div>
            <Button
                className={classNames(styles.Info__Refresh, {
                    [styles.Info__Refresh__WatchListMode]:
                        isMobile && watchListMode
                })}
                theme="link"
                text={isMobile ? '' : 'Refresh'}
                startIcon={
                    <SvgRefresh
                        color={isDarkModeEnabled ? '#b2c0f2' : '#273c83'}
                    />
                }
                onClick={onRefreshClick}
                testId="RefreshButton"
            />
        </div>
    );
};
