import {
    Pagination,
    Sorting,
    SORTING_DIRECTION
} from '@customTypes/general/general.types';

type FixedSorters =
    | 'partNumber'
    | 'clientSerial'
    | 'initialInboundDate'
    | 'name'
    | 'displayName'
    | 'lastModified'
    | 'surName'
    | '-surName'
    | 'givenName'
    | '-givenName'
    | 'Code';

interface IParams {
    sorts: Sorting;
    pagination: Pagination;
    accountNumber?: string;
    subAccountNumber?: string;
    consignorSsl?: string;
    clientItemId?: string;
    filter: string;
    filterFields: string[] | string;
    withoutCaseSensitive?: boolean;
    withoutBaseQueries?: boolean;
    fixedSorter?: FixedSorters;
    additionalFixedSorter?: FixedSorters | FixedSorters[];
}

const queryBuilder = (data: string[]) => {
    return data.filter((item) => item).join(',');
};

export const getBasicSort = (sorts: Sorting) => {
    return sorts.direction === SORTING_DIRECTION.DESC
        ? `-${sorts.field}`
        : sorts.field;
};

export const getBasePaginationBody = (dto: IParams) => {
    const allFilteredFields = Array.isArray(dto.filterFields)
        ? dto.filterFields.join('|')
        : dto.filterFields;

    const sorting =
        dto.fixedSorter && !getBasicSort(dto.sorts).includes(dto.fixedSorter)
            ? `${getBasicSort(dto.sorts)},${dto.fixedSorter}`
            : getBasicSort(dto.sorts);

    const getAdditionalSorting = () => {
        const noAdditionalSorting = !(
            dto.additionalFixedSorter && dto.additionalFixedSorter.length
        );

        if (noAdditionalSorting) return '';

        return Array.isArray(dto.additionalFixedSorter)
            ? dto.additionalFixedSorter.join(',')
            : dto.additionalFixedSorter;
    };

    const allSorts = dto.additionalFixedSorter
        ? `${sorting},${getAdditionalSorting()}`
        : sorting;

    const accountNumberFilter = `AccountNum==${dto?.accountNumber}`;
    const subAccountNumberFilter = `SubAccountNum==${dto?.subAccountNumber}`;
    const consignorSsl = dto.consignorSsl
        ? `ConsignorSsl==${dto.consignorSsl}`
        : '';

    const clientItemId = dto.clientItemId
        ? `ClientItemId==${dto.clientItemId}`
        : '';

    const baseQueries = dto.withoutBaseQueries
        ? ''
        : `${queryBuilder([
              accountNumberFilter,
              subAccountNumberFilter,
              consignorSsl,
              clientItemId
          ])},`;

    const withFilter = baseQueries || allFilteredFields || dto.filter;

    const filter = withFilter
        ? `${baseQueries}(${allFilteredFields})@=${
              dto.withoutCaseSensitive ? '*' : ''
          }${dto.filter}`
        : '';

    return {
        filters: filter,
        sorts: allSorts,
        page: dto.pagination.page,
        pageSize: dto.pagination.pageSize
    };
};
