import React from 'react';

import { Col, Row } from 'antd';
import cn from 'classnames';

import { DATE_FORMAT } from '@apis/users/UsersApi.types';
import { Button } from '@atoms/button/Button';
import { Label, LabelVariants } from '@atoms/label/Label';
import { GoToOrderDto } from '@customTypes/notifications/notifications.type';
import { getTimeAgo } from '@organisms/notificationsPanel/shared/notificationsUtils';
import { CardContentRow } from '@organisms/notificationsPanel/shared/сardContentRow/CardContentRow';
import { ORDER_CONFIRMATION_STATUS } from '@store/slices/notifications/notifications';
import { SvgExpandDown } from 'components/icons';
import { VoidFunction, ListItem } from 'utils/types/general/general.types';

import styles from './Card.module.less';

const getLabelByStatus = (
    status: ORDER_CONFIRMATION_STATUS,
    isRead: boolean
) => {
    const getConfig = () =>
        ({
            [ORDER_CONFIRMATION_STATUS.CONFIRMED]: {
                variant: LabelVariants.GREEN,
                name: 'Confirmed'
            },
            [ORDER_CONFIRMATION_STATUS.REJECTED]: {
                variant: LabelVariants.RED,
                name: 'Rejected'
            },
            [ORDER_CONFIRMATION_STATUS.PENDING]: {
                variant: LabelVariants.YELLOW,
                name: 'Pending'
            }
        }[status]);

    return (
        <Label
            {...getConfig()}
            className={cn({
                [styles.Title__Read]: isRead
            })}
        />
    );
};

export interface CardPropsBase {
    id: number;
    orderId?: number;
    status: ORDER_CONFIRMATION_STATUS;
    isRead: boolean;
    account: string;
    subAccount: string;
    referenceNumbers: ListItem[];
    totalCount?: number;
    proNumber: string | null;
    proNumberAsLink?: boolean;
    rejectionReasons: string[] | null;

    createdDate: string;
    dateFormatKey?: DATE_FORMAT;
    type: string;
    goToDetailsPage: (data: GoToOrderDto) => void;
    onShowMoreBtnClick?: VoidFunction;
}

type CardProps = CardPropsBase;

export const Card = ({
    id,
    orderId,
    status,
    isRead,
    account,
    subAccount,
    referenceNumbers,
    proNumber,
    proNumberAsLink,
    rejectionReasons,
    createdDate,
    dateFormatKey,
    type,
    onShowMoreBtnClick,
    totalCount,
    goToDetailsPage
}: CardProps) => {
    const accountRowValue = `${account}${subAccount ? `/${subAccount}` : ''}`;
    const rejectionReasonRowValue = `${rejectionReasons?.join(';\r\n')};`;

    return (
        <div
            className={cn(styles.Card, {
                [styles.Card__Read]: isRead
            })}
            data-testid="NotificationCard"
        >
            <Row>
                <Col data-testid="NotificationCardLeftColumn" xs={24} md={20}>
                    <div
                        data-testid="NotificationCardHeader"
                        className={styles.Header}
                    >
                        <div
                            className={cn(styles.Title, {
                                [styles.Title__Read]: isRead
                            })}
                        >
                            Order Confirmation Status
                        </div>
                    </div>
                    <div
                        data-testid="NotificationCardContent"
                        className={styles.Content}
                    >
                        <CardContentRow
                            testId="AccountRow"
                            label="Account"
                            orderId={orderId}
                            value={accountRowValue}
                            isRead={isRead}
                            notificationId={id}
                        />
                        {referenceNumbers.map((item, i) => {
                            if (item.value) {
                                return (
                                    <CardContentRow
                                        key={item.value}
                                        orderId={orderId}
                                        testId={`ReferenceNumber${i}Row`}
                                        label={item.label}
                                        value={item.value}
                                        isRead={isRead}
                                        notificationId={id}
                                    />
                                );
                            }
                            return null;
                        })}
                        {status === ORDER_CONFIRMATION_STATUS.CONFIRMED && (
                            <CardContentRow
                                testId="ProNumberRow"
                                label="PRO Number"
                                orderId={orderId}
                                value={proNumber as string}
                                isRead={isRead}
                                proNumberAsLink={proNumberAsLink}
                                goToDetailsPage={goToDetailsPage}
                                notificationId={id}
                            />
                        )}
                        {status === ORDER_CONFIRMATION_STATUS.REJECTED && (
                            <CardContentRow
                                orderId={orderId}
                                testId="RejectionReasonRow"
                                isRead={isRead}
                                label="Rejection Reason(s)"
                                value={rejectionReasonRowValue}
                                notificationId={id}
                            />
                        )}
                        <div
                            className={cn(styles.OrderDetailsLink, {
                                [styles.OrderDetailsLink__Read]: isRead
                            })}
                            onClick={() =>
                                orderId !== undefined &&
                                goToDetailsPage({
                                    orderId,
                                    notificationId: id,
                                    isRead
                                })
                            }
                        >
                            Order Details
                        </div>
                    </div>
                    <div
                        data-testid="NotificationCardFooter"
                        className={styles.Footer}
                    >
                        {`${getTimeAgo({
                            date: createdDate,
                            dateFormatKey
                        })} • ${type}`}
                    </div>
                </Col>
                <Col
                    data-testid="NotificationCardRightColumn"
                    xs={24}
                    md={4}
                    className={styles.RightColumn}
                >
                    {getLabelByStatus(status, isRead)}
                    {onShowMoreBtnClick && totalCount && (
                        <Button
                            testId="ShowMoreButton"
                            size="small"
                            theme="link"
                            text={`${totalCount - 1} more`}
                            endIcon={<SvgExpandDown />}
                            onClick={onShowMoreBtnClick}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};
