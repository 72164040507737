import React, { MouseEvent } from 'react';

import classNames from 'classnames';

import { Loader } from '@atoms/loader/Loader';

import styles from './Button.module.less';

export type IButtonTheme = 'primary' | 'secondary' | 'tertiary' | 'link';

export interface IButton {
    text: string;
    id?: string;
    className?: string;
    size?: 'small';
    htmlType?: 'button' | 'submit';
    theme?: IButtonTheme;
    testId?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    startIcon?: React.ReactNode;
    disabled?: boolean;
    isLoading?: boolean;
    endIcon?: React.ReactNode;
}

export const Button = ({
    text,
    className,
    theme = 'tertiary',
    testId,
    onClick,
    disabled,
    htmlType = 'button',
    size,
    startIcon,
    endIcon,
    isLoading,
    id
}: IButton) => {
    const themeClassName = classNames(styles.Button, className, {
        [styles.Button__Disabled]: disabled && theme !== 'link',
        [styles.Button__Primary]: theme === 'primary',
        [styles.Button__Secondary]: theme === 'secondary',
        [styles.Button__Tertiary]: theme === 'tertiary',
        [styles.Button__Link]: theme === 'link' && !disabled,
        [styles.Button__Link_Disabled]: theme === 'link' && disabled,
        [styles.Button__Loading]: isLoading,
        [styles.Button__Small]: size === 'small'
    });

    return (
        <>
            <button
                className={themeClassName}
                onClick={onClick}
                data-testid={testId}
                type={htmlType}
                disabled={disabled}
                id={id}
            >
                {!isLoading ? (
                    <>
                        {startIcon}
                        {text && <span>{text}</span>}
                        {endIcon}
                    </>
                ) : (
                    <>
                        Loading
                        <Loader size="xSmall" theme="white" />
                    </>
                )}
            </button>
        </>
    );
};
