import React, { ReactNode, useState } from 'react';

import classNames from 'classnames';

import { Button } from '@atoms/button/Button';
import { Popover } from '@atoms/popover/Popover';
import { VoidFunction } from '@customTypes/general/general.types';
import { SvgArrow } from '@icons/index';
import styles from '@molecules/filters/filter/Filter.module.less';

export interface IFilterProps {
    withArrow?: boolean;
    customId?: string;
    icon?: ReactNode;
    mainFilterContent?: ReactNode;
    filterName: string;
    isSelected?: boolean;
    onOpenFilter?: VoidFunction;
    onApply?: VoidFunction;
    onClick?: VoidFunction;
    openedOutside?: boolean;
    isDataLoading?: boolean;
    disabled?: boolean;
    isApplyDisabled?: boolean;
    setOpenedOutside?: React.Dispatch<React.SetStateAction<boolean>>;
    onClearSearch?: VoidFunction;
    dataTestId?: string;
    destroyOnClose?: boolean;
}

export const Filter = ({
    withArrow = true,
    customId,
    icon,
    filterName,
    isSelected,
    mainFilterContent,
    onOpenFilter,
    onApply,
    onClick,
    openedOutside,
    disabled,
    isApplyDisabled,
    setOpenedOutside,
    onClearSearch,
    dataTestId = 'OrderListFilter',
    destroyOnClose = false
}: IFilterProps) => {
    const [open, setOpen] = useState(false);

    const handleVisibleChange = () => {
        onClearSearch?.();
        if (!disabled) {
            setOpen(!open);
            setOpenedOutside?.(!open);
            onOpenFilter?.();
        }
    };

    const onClickApply = () => {
        setOpen(false);
        setOpenedOutside?.(false);
        onApply?.();
    };

    const onAllFilterClick = () => {
        onClick?.();
    };

    const isOpened =
        typeof openedOutside !== 'undefined' ? openedOutside : open;

    return (
        <Popover
            showIcon={false}
            triggerContent={
                <Button
                    endIcon={
                        withArrow && (
                            <SvgArrow
                                color={disabled ? '#8e94a9' : '#273c83'}
                                className={classNames({
                                    [styles.Arrow]: isOpened
                                })}
                            />
                        )
                    }
                    startIcon={
                        <div
                            className={classNames(styles.Button__Icon, {
                                [styles.Button__IconDisabled]: disabled
                            })}
                        >
                            {icon}
                        </div>
                    }
                    id={customId}
                    text={filterName}
                    theme="tertiary"
                    className={classNames(styles.Button, {
                        [styles.Button__Selected]: isSelected,
                        [styles.Button__Disabled]: disabled
                    })}
                    onClick={onAllFilterClick}
                    testId={dataTestId}
                    disabled={disabled}
                />
            }
            getPopupContainer={(trigger) =>
                trigger.parentElement || document.body
            }
            placement="bottomRight"
            onVisibleChange={handleVisibleChange}
            overlayClass={styles.Popover}
            visible={isOpened}
            className={styles.Popover}
            popoverContent={
                mainFilterContent && (
                    <div className={styles.Content} data-testid="FilterContent">
                        {mainFilterContent}
                        <div className={styles.Content__ButtonsBlock}>
                            <Button
                                text="Apply"
                                theme="secondary"
                                className={styles.Content__Button}
                                onClick={onClickApply}
                                data-testid="FilterApplyButton"
                                disabled={isApplyDisabled}
                            />
                        </div>
                    </div>
                )
            }
            destroyTooltipOnHide={destroyOnClose}
        />
    );
};
