import React from 'react';

import classNames from 'classnames';

import { SvgCross } from '@icons/Cross';
import { SvgCross6 } from '@icons/Cross6';

import styles from './SearchBadge.module.less';

interface IProps {
    text: string;
    removeBadges: (id: string) => void;
    withoutDelete?: boolean;
    stopPropagation?: boolean;
    id: string;
    crossSize?: 'small' | 'medium';
    maxWidthForText?: boolean;
    maxLinesForText?: number;
}

export const SearchBadge = ({
    text,
    removeBadges,
    withoutDelete = false,
    id,
    crossSize = 'medium',
    maxWidthForText = false,
    maxLinesForText,
    stopPropagation = false
}: IProps) => {
    const onClick = () => {
        removeBadges(id);
    };

    const onPreventPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
        if (stopPropagation) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return (
        <div
            onClick={onPreventPropagation}
            onMouseDown={onPreventPropagation}
            onPointerDown={onPreventPropagation}
            className={styles.SearchBadge}
            data-testid={`${id}Badge`}
        >
            <div
                data-testid="BadgeText"
                className={classNames({
                    [styles.SearchBadge__Text]: maxWidthForText,
                    [styles.SearchBadge__Lines]: maxLinesForText
                })}
                style={{
                    WebkitLineClamp: maxLinesForText
                }}
            >
                {text}
            </div>
            {!withoutDelete && (
                <>
                    <div className={styles.SearchBadge__Separator} />
                    <div
                        onClick={onClick}
                        className={styles.SearchBadge__Cross}
                        data-testid="removeBadgeButton"
                    >
                        {crossSize === 'medium' ? <SvgCross /> : <SvgCross6 />}
                    </div>
                </>
            )}
        </div>
    );
};
