import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.d';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { bulkResponseHandler } from '@apis/bulkResponseHandler';
import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import { IQueryWithIntercept } from '@apis/queryWithIntercept';
import {
    REQUESTS_PATH,
    REQUESTS_PATH_DEFECTIVE
} from '@apis/transfersByBulk/TransfersByBulk.type';
import { RequestStatusWithEmptyData } from '@customTypes/api/api.types';
import {
    BULK_TEMPLATES_NAMES,
    ColumnMappingDTO,
    ColumnMappingResponse,
    DownloadTemplateDTO,
    GetAvailableCarriersDTO,
    GetAvailableCarriersResponse,
    GroupingOrdersDTO,
    GroupingOrdersResponse,
    SubmitOrdersDTO,
    ValidateFileDTO,
    ValidateFileResponse
} from '@customTypes/bulkOrder/bulk.types';
import { getServicesWithIds } from '@helpers/activeOrder';

const getCommonEndpoints = (
    builder: EndpointBuilder<IQueryWithIntercept, never, string>,
    requestsPath: typeof REQUESTS_PATH | typeof REQUESTS_PATH_DEFECTIVE,
    isDefective?: boolean
) => ({
    getDownloadTransfersTemplate: builder.mutation<File, DownloadTemplateDTO>({
        query: (dto) => ({
            url: requestsPath.DOWNLOAD_TEMPLATE,
            headers: {
                responseType: 'blob',
                'content-type': 'application/octet-stream'
            },
            responseHandler: async (response) =>
                bulkResponseHandler(
                    response,
                    dto,
                    isDefective
                        ? BULK_TEMPLATES_NAMES.TRANSFERS_DEFECTIVE
                        : BULK_TEMPLATES_NAMES.TRANSFERS
                ),
            cache: 'no-cache',
            method: 'POST',
            body: dto
        })
    }),
    resetTransfersFile: builder.mutation<RequestStatusWithEmptyData, string>({
        query: (id) => ({
            url: requestsPath.RESET_FILE.replace(':id', id),
            method: 'DELETE'
        })
    }),
    getTransfersColumnsMapping: builder.mutation<
        ColumnMappingResponse,
        ColumnMappingDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_COLUMNS_MAPPING,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    validateTransfersFile: builder.mutation<
        ValidateFileResponse,
        ValidateFileDTO
    >({
        query: (dto) => ({
            url: requestsPath.VALIDATE_FILE,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    getAvailableCarriers: builder.mutation<
        GetAvailableCarriersResponse,
        GetAvailableCarriersDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_AVAILABLE_CARRIERS,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        }),
        transformResponse(response: GetAvailableCarriersResponse) {
            return getServicesWithIds(response) as GetAvailableCarriersResponse;
        }
    }),
    getTransfersGroupingOrders: builder.mutation<
        GroupingOrdersResponse,
        GroupingOrdersDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_GROUPING,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    submitTransfersOrders: builder.mutation<
        RequestStatusWithEmptyData,
        SubmitOrdersDTO
    >({
        query: (dto) => ({
            url: requestsPath.SUBMIT_ORDERS,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    })
});

export const transfersByBulkApi = createApi({
    reducerPath: 'transfersByBulkApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, REQUESTS_PATH)
});
export const transfersByBulkDefectiveApi = createApi({
    reducerPath: 'transfersByBulkDefectiveApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) =>
        getCommonEndpoints(builder, REQUESTS_PATH_DEFECTIVE, true)
});

export const {
    useGetDownloadTransfersTemplateMutation,
    useResetTransfersFileMutation,
    useGetTransfersColumnsMappingMutation,
    useValidateTransfersFileMutation,
    useGetAvailableCarriersMutation,
    useGetTransfersGroupingOrdersMutation,
    useSubmitTransfersOrdersMutation
} = transfersByBulkApi;

export const {
    useGetDownloadTransfersTemplateMutation:
        useDefectiveGetDownloadTransfersTemplateMutation,
    useResetTransfersFileMutation: useDefectiveResetTransfersFileMutation,
    useGetTransfersColumnsMappingMutation:
        useDefectiveGetTransfersColumnsMappingMutation,
    useValidateTransfersFileMutation: useDefectiveValidateTransfersFileMutation,
    useGetAvailableCarriersMutation: useDefectiveGetAvailableCarriersMutation,
    useGetTransfersGroupingOrdersMutation:
        useDefectiveGetTransfersGroupingOrdersMutation,
    useSubmitTransfersOrdersMutation: useDefectiveSubmitTransfersOrdersMutation
} = transfersByBulkDefectiveApi;
