import React, { useEffect } from 'react';

import classNames from 'classnames';

import styles from '@atoms/emptyAccount/EmptyAccount.module.less';
import { SvgSuitcase } from '@icons/Suitcase';

interface IProps {
    className?: string;
}

export const EmptyAccount = ({ className }: IProps) => {
    useEffect(() => {
        window.history.replaceState(
            { step: 'select-account' },
            `Step select-account`,
            `?step=select-account`
        );
    }, []);

    return (
        <div className={classNames(styles.EmptyAccount, className)}>
            <div className={styles.EmptyAccount__Title}>Select Account</div>
            <div className={styles.EmptyAccount__Subtitle}>
                <div>To start working with orders please select</div>
                <div className={styles.EmptyAccount__RowWithIcon}>
                    <SvgSuitcase color="#8E94A9" width={22} />
                    Account in the top bar
                </div>
            </div>
        </div>
    );
};
