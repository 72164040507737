import React from 'react';

import cn from 'classnames';

import { OrderTicket } from '@apis/trackOrders/TrackOrdersApi.types';
import { Label, LabelVariants } from '@atoms/label/Label';
import { Loader } from '@atoms/loader/Loader';
import { ToolTip } from '@atoms/tooltip/ToolTip';
import {
    getNotification,
    NOTIFICATION_TYPES
} from '@notifications/Notification';
import {
    SvgArrowFullRight,
    SvgCopyIcon,
    SvgWarehouse,
    SvgTracking
} from 'components/icons';

import styles from './OrderTicketRow.module.less';

interface IProps {
    tickets?: OrderTicket[];
    isLoading?: boolean;
    searchResult?: string;
    onClick: (e: React.MouseEvent, proNum?: string) => void;
}

export const OrderTicketRow = ({
    tickets,
    isLoading,
    searchResult,
    onClick
}: IProps) => {
    const searchValue = searchResult?.toLowerCase();

    const checkIsAirwayBillMatched = (value: string[]) => {
        return Boolean(
            searchValue &&
                value.find((item) => item.toLowerCase() === searchValue)
        );
    };

    const renderOriginDestination = ({
        warehouse,
        warehouseAlias,
        city,
        country,
        isWarehouse
    }: {
        warehouse: string | null;
        warehouseAlias: string | null;
        city: string | null;
        country: string;
        isWarehouse: boolean;
    }) => {
        const cityAndCountry = [city, country].filter(Boolean).join(', ');
        return (
            <div className={styles.Row}>
                {isWarehouse && <SvgWarehouse className={styles.Icon} />}
                <div className={styles.Row}>
                    {warehouse
                        ? `${warehouse}${
                              warehouseAlias ? ` (${warehouseAlias})` : ''
                          }`
                        : cityAndCountry}
                </div>
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className={styles.Content__Loader}>
                <Loader size="small" />
            </div>
        );
    }
    const onCopy =
        (dataToCopy: string) => (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            navigator.clipboard.writeText(dataToCopy);
            getNotification({
                title: 'Copied',
                duration: 5,
                type: NOTIFICATION_TYPES.SUCCESS
            });
        };

    const spawnCopy = (data: string) => (
        <span className={styles.CopyBlock} onClick={onCopy(data)}>
            <SvgCopyIcon />
        </span>
    );

    return (
        <div className={styles.Container} data-testid="OrderTicketRow">
            <div className={styles.Titles} data-testid="OrderTicketRowTitles">
                <div>PRO</div>
                <div>Ticket Type</div>
                <div>Status</div>
                <div>Transport Type</div>
                <div>Origin</div>
                <div>Destination</div>
            </div>
            {tickets?.map(
                (
                    {
                        proNum,
                        direction,
                        currentTicketFlag,
                        ticketStatus,
                        serviceLevel,
                        destinationCountry,
                        originCountry,
                        carrier,
                        awbList,
                        isTrackable,
                        destinationCity,
                        destinationSSL,
                        destinationSSLAlias,
                        originCity,
                        originSSL,
                        originSSLAlias,
                        destinationIsSsl,
                        originIsSsl,
                        ...aa
                    },
                    index
                ) => {
                    const airwayBillList = awbList?.split(',') || [];
                    const airwayBillHintItems = (
                        <ul className={styles.AirWayHintList}>
                            {airwayBillList.map((awbItem, index) => (
                                <li key={index}>{awbItem}</li>
                            ))}
                        </ul>
                    );
                    const isAirwayBillMatched =
                        checkIsAirwayBillMatched(airwayBillList);
                    const isProMatched = searchValue === proNum.toLowerCase();

                    return (
                        <div
                            className={styles.Content}
                            key={`${proNum}_${index}`}
                            data-testid="OrderTicketData"
                            onClick={(e) => onClick(e, proNum)}
                        >
                            <div className={styles.Pro}>
                                <div
                                    className={cn(styles.CurrentTicket, {
                                        [styles.CurrentTicket__Hidden]:
                                            !currentTicketFlag
                                    })}
                                />
                                <div
                                    data-testid="OrderTicketProNumber"
                                    className={cn(styles.CopyBlock, {
                                        [styles.Text__Matched]: isProMatched
                                    })}
                                >
                                    {proNum}
                                </div>
                                {spawnCopy(proNum)}
                            </div>
                            <div
                                className={cn(
                                    styles.Text__Grey,
                                    styles.Content__Divider
                                )}
                            >
                                {direction}
                            </div>
                            <div
                                className={cn(
                                    styles.Status,
                                    styles.Content__Divider
                                )}
                                data-testid="OrderTicketStatus"
                            >
                                <Label
                                    variant={LabelVariants.NEUTRAL}
                                    name={ticketStatus}
                                />
                            </div>
                            <div
                                className={cn(
                                    styles.Row,
                                    styles.Content__Divider
                                )}
                                data-testid="OrderTicketTransportType"
                            >
                                {serviceLevel ? (
                                    <div>{serviceLevel}</div>
                                ) : (
                                    <div className={styles.Row}>
                                        {isTrackable && (
                                            <ToolTip title="Trackable ticket">
                                                <SvgTracking />
                                            </ToolTip>
                                        )}
                                        {carrier && (
                                            <div>{`${carrier}${
                                                awbList ? ',' : ''
                                            }`}</div>
                                        )}
                                        {awbList && (
                                            <>
                                                <div
                                                    className={cn(styles.Row, {
                                                        [styles.Text__Matched]:
                                                            isAirwayBillMatched
                                                    })}
                                                >
                                                    <div>
                                                        {airwayBillList[0]}
                                                    </div>
                                                    {airwayBillList.length ===
                                                        1 &&
                                                        spawnCopy(
                                                            airwayBillList[0]
                                                        )}

                                                    {airwayBillList.length >
                                                        1 && (
                                                        <div
                                                            onClick={(e) =>
                                                                e.stopPropagation()
                                                            }
                                                        >
                                                            <ToolTip
                                                                title={
                                                                    airwayBillHintItems
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.Text__Hint
                                                                    }
                                                                >
                                                                    +
                                                                    {airwayBillList.length -
                                                                        1}
                                                                </div>
                                                            </ToolTip>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div
                                className={styles.Row__Grow}
                                data-testid="OrderTicketOrigin"
                            >
                                {renderOriginDestination({
                                    city: originCity,
                                    country: originCountry,
                                    warehouse: originSSL,
                                    warehouseAlias: originSSLAlias,
                                    isWarehouse: originIsSsl
                                })}

                                <SvgArrowFullRight className={styles.Icon} />
                            </div>

                            <div
                                className={styles.Row}
                                data-testid="OrderTicketDestination"
                            >
                                {renderOriginDestination({
                                    city: destinationCity,
                                    country: destinationCountry,
                                    warehouse: destinationSSL,
                                    warehouseAlias: destinationSSLAlias,
                                    isWarehouse: destinationIsSsl
                                })}
                            </div>
                        </div>
                    );
                }
            )}
        </div>
    );
};
