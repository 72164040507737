import React from 'react';

import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';

import {
    OrderOriginDestination,
    TransformedTrackOrder
} from '@apis/trackOrders/TrackOrdersApi.types';
import { DATE_FORMAT, TIME_FORMAT } from '@apis/users/UsersApi.types';
import { Label } from '@atoms/label/Label';
import { SortingCell } from '@atoms/table/sortingCell/SortingCell';
import {
    defaultSortDirections,
    Sorting
} from '@customTypes/general/general.types';
import { formatDateString } from '@helpers/dateAndTimeFormatters';
import { getStatusVariant, PROGRESS_STATUSES } from '@helpers/orderStatus';
import { getFieldSorting } from '@helpers/sorting';
import { capitalize } from '@helpers/stringHelper';
import { PartsCount } from '@organisms/trackOrders/orderList/shared/partsCount/PartsCount';
import { ReferenceNumbers } from '@organisms/trackOrders/orderList/shared/referenceNumbers/ReferenceNumbers';
import { SvgWarehouse } from 'components/icons';

import 'flag-icons/css/flag-icons.min.css';

import styles from './OrdersList.module.less';

export const renderOriginDestination = ({
    originDestination,
    watchListMobileMode = false
}: {
    originDestination: OrderOriginDestination;
    watchListMobileMode?: boolean;
}) => {
    const { city, countryCode, sslAlias, sslCode, isSsl, companyName } =
        originDestination;
    const alias = sslAlias ? `(${sslAlias})` : '';
    const countryCodeData = countryCode
        ? `${city ? ', ' : ''}${countryCode}`
        : '';
    const cityData = city || '';

    return (
        <div
            className={classNames(styles.Row, {
                [styles.Row__WatchListMobileMode]: watchListMobileMode
            })}
        >
            <span
                className={classNames(
                    `fi fi-${countryCode?.toLowerCase()}`,
                    styles.Origin
                )}
            />
            {isSsl && <SvgWarehouse className={styles.Origin} />}
            <div>
                {(city || countryCode) &&
                    `${capitalize(cityData)}${countryCodeData}`}

                <div>
                    {isSsl ? (
                        `${sslCode} ${alias}`
                    ) : (
                        <span className={styles.CompanyName}>
                            {companyName && capitalize(companyName)}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export const renderDateColumn = ({
    dateActual,
    dateEstimated,
    isTimeZone = true,
    timeZone,
    dateFormatKey,
    timeFormatKey,
    watchListMobileMode = false
}: {
    dateActual: string | null;
    dateEstimated: string | null;
    isTimeZone?: boolean | null;
    timeZone: string | null;
    dateFormatKey?: DATE_FORMAT;
    timeFormatKey?: TIME_FORMAT;
    watchListMobileMode?: boolean | null;
}) => {
    const dateToDisplay = dateActual || dateEstimated;
    const formattedDate =
        dateToDisplay &&
        formatDateString({
            date: dateToDisplay,
            dateFormatKey,
            timeFormatKey
        }).monthDateYearAndTime;

    return (
        dateToDisplay && (
            <div
                className={classNames(styles.Column, {
                    [styles.Column__WatchListMobileMode]: watchListMobileMode
                })}
            >
                <div
                    className={classNames(styles.DateSection, {
                        [styles.DateSection__WatchListMobileMode]:
                            watchListMobileMode
                    })}
                >
                    {formattedDate}
                    {timeZone && isTimeZone && ` (${timeZone})`}
                </div>
                {!isTimeZone && (
                    <div className={styles.Date}>
                        {dateActual ? 'Actual' : 'Estimated'}
                    </div>
                )}
            </div>
        )
    );
};

interface IArgs {
    sorting: Sorting;
    search?: string;
    timeFormatKey?: TIME_FORMAT;
    dateFormatKey?: DATE_FORMAT;
    statusParam?: string;
}

export const getOrdersListColumns = ({
    sorting,
    search,
    timeFormatKey,
    dateFormatKey,
    statusParam
}: IArgs): ColumnsType<TransformedTrackOrder> => {
    const isTotalStatusSelected = statusParam === 'Total';
    return [
        {
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            sortDirections: defaultSortDirections,
            sorter: isTotalStatusSelected,
            width: 120,
            sortOrder: getFieldSorting('orderStatus', sorting),
            title: ({ sortColumns }) =>
                isTotalStatusSelected ? (
                    <SortingCell id="orderStatus" sortColumns={sortColumns}>
                        <span id="orderStatus">Order Status</span>
                    </SortingCell>
                ) : (
                    <span id="orderStatus">Order Status</span>
                ),
            render: (orderStatus: PROGRESS_STATUSES) => {
                const variant = getStatusVariant(orderStatus);
                return variant ? (
                    <Label variant={variant} name={orderStatus} />
                ) : null;
            }
        },
        {
            dataIndex: 'orderType',
            key: 'orderType',
            sortDirections: defaultSortDirections,
            sorter: true,
            width: 110,
            sortOrder: getFieldSorting('orderType', sorting),
            title: ({ sortColumns }) => (
                <SortingCell id="orderType" sortColumns={sortColumns}>
                    <span id="orderType">Order Type</span>
                </SortingCell>
            )
        },
        {
            dataIndex: 'referenceNumber',
            key: 'referenceNumber',
            sortDirections: defaultSortDirections,
            sorter: true,
            width: 150,
            sortOrder: getFieldSorting('referenceNumber', sorting),
            title: ({ sortColumns }) => (
                <SortingCell id="referenceNumber" sortColumns={sortColumns}>
                    <span id="referenceNumber">Reference Number</span>
                </SortingCell>
            ),

            render: (_, { referenceNumbers }) => (
                <ReferenceNumbers
                    referenceNumbers={referenceNumbers}
                    search={search}
                />
            )
        },
        {
            dataIndex: 'origin',
            key: 'origin',
            title: 'Origin',
            render: (_, { origin }) =>
                renderOriginDestination({ originDestination: origin })
        },
        {
            dataIndex: 'destination',
            key: 'destination',
            title: 'Destination',
            render: (_, { destination }) =>
                renderOriginDestination({ originDestination: destination })
        },
        {
            dataIndex: 'pickUpDate',
            key: 'pickUpDate',
            sortDirections: defaultSortDirections,
            sorter: true,
            sortOrder: getFieldSorting('pickUpDate', sorting),
            title: ({ sortColumns }) => (
                <SortingCell id="pickUpDate" sortColumns={sortColumns}>
                    <span id="pickUpDate">Pick Up Date</span>
                </SortingCell>
            ),
            render: (
                _,
                { pickUpDateActual, pickUpDateEstimated, pickUpDateTimeZone }
            ) =>
                renderDateColumn({
                    dateActual: pickUpDateActual,
                    dateEstimated: pickUpDateEstimated,
                    timeZone: pickUpDateTimeZone,
                    dateFormatKey,
                    timeFormatKey,
                    isTimeZone: false
                })
        },
        {
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            sortDirections: defaultSortDirections,
            sorter: true,
            sortOrder: getFieldSorting('deliveryDate', sorting),
            title: ({ sortColumns }) => (
                <SortingCell id="deliveryDate" sortColumns={sortColumns}>
                    <span id="deliveryDate">Delivery Date</span>
                </SortingCell>
            ),
            render: (
                _,
                {
                    deliveryDateActual,
                    deliveryDateEstimated,
                    deliveryDateTimeZone
                }
            ) =>
                renderDateColumn({
                    dateActual: deliveryDateActual,
                    dateEstimated: deliveryDateEstimated,
                    timeZone: deliveryDateTimeZone,
                    dateFormatKey,
                    timeFormatKey,
                    isTimeZone: false
                })
        },
        {
            title: 'Parts',
            dataIndex: 'parts',
            key: 'parts',
            width: 80,
            align: 'center',
            render: (_, { partsCount }) => (
                <PartsCount partsCount={partsCount} />
            )
        }
    ];
};
